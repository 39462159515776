enum StorageOperations {
  UPLOAD = 'upload',
  DOWNLOAD = 'download',
  LIST = 'list',
  DELETE = 'delete',
}

type GetSasTokenResponse = {
  data: string;
};

export { StorageOperations, GetSasTokenResponse };
