import { ICampaignFormAction } from './campaignForm.actions';
import { ICampaignFormContext, ICampaignFormReducerAction } from './campaignForm.types';

export const campaignFormReducer = (
  state: ICampaignFormContext,
  action: ICampaignFormReducerAction
): ICampaignFormContext => {
  switch (action.type) {
    case ICampaignFormAction.CAMPAIGN_FORM_REQUEST: {
      return {
        ...state,
        isLoading: true,
        campaignFormError: null,
      };
    }
    case ICampaignFormAction.CAMPAIGN_FORM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        campaignForm: action.payload,
        campaignFormError: null,
      };
    }
    case ICampaignFormAction.CAMPAIGN_FORM_ERROR: {
      return {
        ...state,
        isLoading: false,
        campaignFormError: action.payload,
      };
    }
    case ICampaignFormAction.CAMPAIGN_FORM_VALIDATION: {
      return {
        ...state,
        isCampaignFormValid: action.payload,
      };
    }
    case ICampaignFormAction.CAMPAIGN_FORM_UPLOADING: {
      return {
        ...state,
        isUploading: action.payload,
      };
    }
    default:
      return state;
  }
};
