import { Settings as IconSettings, Tiles2 } from '@admin-portal-shared-components/icons';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { useSidebar, createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { OPTIMIZELY_PROJECT } from './optimizely/config';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from './i18n';
import Router from './Router';
import { EnvConfig, EnvProvider } from './utils/hooks/EnvProvider/EnvProvider';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-nft-campaign-mfe',
    seed: 'bees-hub-nft-campaign-mfe',
  });
  useSidebar({
    items: [{ id: '1', title: 'Manage Campaigns', icon: () => <Tiles2 />, path: '/nftcore' }],
    utils: [
      {
        id: '2',
        title: 'Settings',
        icon: () => <IconSettings />,
        path: '/settings',
        target: '_self',
      },
    ],
  });

  createFeatureToggleServiceV2(OPTIMIZELY_PROJECT, props.OPTIMIZELY_KEY);

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
