import { useCallback, useContext } from 'react';
import { useEnvContext } from './../../../../utils/hooks/EnvProvider/EnvProvider';

import getApiHost from '../../../../services/host/HostService';
import { campaignFormInitialState } from '../../../../utils/context/campaignFormContext/campaignForm.actions';
import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { getCampaignService } from '../../services/getCampaign/getCampaign';

const useGetCampaign = (id: string): VoidFunction => {
  const { setCampaignFormError, setCampaignFormSuccess, setCampaignFormRequest } =
    useContext(CampaignFormContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(async () => {
    try {
      setCampaignFormRequest();
      const campaignFormResponse = await getCampaignService(getApiHost(), BEARERTOKEN, id);
      setCampaignFormSuccess({ ...campaignFormInitialState, ...campaignFormResponse.data });
    } catch (error) {
      setCampaignFormError(error.response.status);
    }
  }, [setCampaignFormRequest, setCampaignFormSuccess, setCampaignFormError]);
};

export { useGetCampaign };
