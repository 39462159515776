import { RadioGroup } from '@admin-portal-shared-components/radio';
import styled from 'styled-components';

export const RadioGroupWrapper = styled(RadioGroup)`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px;
`;

type RadioWrapperProps = {
  isDisabled: boolean;
};

export const RadioWrapper = styled.div<RadioWrapperProps>`
  padding: 20px 15px;
  border: ${(props) => '1px solid ' + (props.isDisabled ? 'rgba(0, 0, 0, 0.15)' : 'black')};
  border-radius: 8px;

  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;

  & label {
    font-weight: bold;
    color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.15)' : 'inherit')};
  }

  & button[data-state='unchecked'] {
    border-color: rgba(0, 0, 0, 0.15);
  }
`;

type RadioItemDescriptionProps = {
  isDisabled: boolean;
};

export const RadioItemDescription = styled.span<RadioItemDescriptionProps>`
  display: inline-block;

  margin: 0px 0px 10px 38px;

  font-size: 14px;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.15)' : 'inherit')};
`;
