import { Dispatch, SetStateAction } from 'react';

const currencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const currencyFocusHandler = (
  e: React.ChangeEvent<any>,
  setPrice: Dispatch<SetStateAction<string>>
) => {
  const price = Number(String(e.target.value).replace(/[^0-9.]+/g, ''));

  if (isNaN(price)) {
    e.target.value = String(e.target.value).replace(/[^0-9.]+/g, '');
    setPrice(String(e.target.value).replace(/[^0-9.]+/g, ''));
  } else {
    e.target.value = !price && price === 0 ? '' : String(price).replace(/[^0-9.]+/g, '');
    setPrice(!price && price === 0 ? '' : String(price).replace(/[^0-9.]+/g, ''));
  }
};

const currencyBlurHandler = (
  e: React.ChangeEvent<any>,
  setPrice: Dispatch<SetStateAction<string>>
) => {
  const price = Number(String(e.target.value).replace(/[^0-9.]+/g, ''));

  if (isNaN(price)) {
    e.target.value = String(e.target.value).replace(/[^0-9.]+/g, '');
    setPrice(String(e.target.value).replace(/[^0-9.]+/g, ''));
  } else {
    e.target.value = !price && price === 0 ? '' : currencyFormatter.format(price);
    setPrice(!price && price === 0 ? '' : currencyFormatter.format(price));
  }
};

export { currencyFormatter, currencyBlurHandler, currencyFocusHandler };
