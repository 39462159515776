import { BlockBlobClient } from '@azure/storage-blob';

const deleteCollection = async (
  blockBlobClient: BlockBlobClient,
  abortControllerSignal?: AbortSignal
): Promise<void> => {
  await blockBlobClient.deleteIfExists({ abortSignal: abortControllerSignal });
};

export { deleteCollection };
