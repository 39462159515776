import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Clipboard } from '@hexa-ui/icons';
import { FileCopyOutlined, OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { formattedDate } from '../../../../../shared-components/FormattedDate/formatted-date';
import { useCampaignId } from '../../../../Home/hooks/useCampaignId/useCampaignId';
import { Network } from '../../../../Home/hooks/useGenerateContract/types';
import { useGenerateContract } from '../../../../Home/hooks/useGenerateContract/useGenerateContract';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { TestContractProps } from '../types';
import { confirmationConfig } from './config';

const TestContract = ({ id }: TestContractProps): JSX.Element => {
  const generateContract = useGenerateContract(id, false, Network.GOERLI);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig();
  const { formatMessage } = useIntl();
  const contract = useCampaignId(id);
  const toastService = useToast();

  const handleClick = async () => {
    setIsLoading(true);
    const errorMessage = await generateContract();
    errorMessage
      ? toastService.notify({ type: TypeToast.ERROR, message: formatMessage({ id: errorMessage }) })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.contentCard.testContract.toastMessage',
          }),
        });
    setIsLoading(false);
  };

  return (
    <CardWrapper data-testid="test-contract">
      <Card border="medium" elevated="small" className="card">
        {!isLoading && (
          <>
            <Clipboard size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.testContract.header',
              })}
            </Heading>
          </>
        )}

        {!contract?.testNetContract?.isPublishing &&
          ((!contract?.testNetContract?.hash &&
            !isLoading &&
            !confirmationStep &&
            !contract?.liveContract?.hash) ||
            (contract?.liveContract?.hash && !contract?.testNetContract?.hash)) && (
            <>
              <Paragraph size="basis">
                {!contract?.liveContract?.hash ? (
                  <>
                    {formatMessage({
                      id: 'campaignDashboardPage.contentCard.testContract.firstState.description',
                    })}
                  </>
                ) : (
                  <>
                    {formatMessage({
                      id: 'campaignDashboardPage.contentCard.testContract.firstState.publishedContractDescription',
                    })}
                  </>
                )}
              </Paragraph>
              {!contract?.liveContract?.hash && (
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => setConfirmationStep(true)}
                  data-testid="test-button"
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.testContract.firstState.button',
                  })}
                </Button>
              )}
            </>
          )}

        {confirmationStep && (
          <Confirmation
            props={data}
            setConfirmationStep={setConfirmationStep}
            setIsConfirmed={setIsConfirmed}
            request={handleClick}
          />
        )}

        {(contract?.testNetContract?.isPublishing ||
          (isLoading && isConfirmed && !contract?.liveContract?.hash)) && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.testContract.loadingMessage',
            })}
          />
        )}

        {!contract?.testNetContract?.isPublishing &&
          ((contract?.testNetContract?.hash && !confirmationStep && !isLoading) ||
            (contract?.liveContract?.hash && contract?.testNetContract?.hash)) && (
            <>
              <Paragraph size="basis" className="longer">
                {formatMessage({
                  id: 'campaignDashboardPage.contentCard.testContract.secondState.description',
                })}
                {contract?.testNetContract?.publishedDate &&
                  formattedDate(contract?.testNetContract?.publishedDate.toString(), 'day')}
              </Paragraph>
              <LinksWrapper data-testid="test-links-wrapper">
                <CopyToClipboard text={contract && contract?.testNetContract?.hash}>
                  <TextLinkWrapper>
                    <FileCopyOutlined />
                    <TextLink>
                      {formatMessage({
                        id: 'campaignDashboardPage.contentCard.testContract.secondState.firstLink',
                      })}
                    </TextLink>
                  </TextLinkWrapper>
                </CopyToClipboard>
                <TextLinkWrapper>
                  <OpenInNew />
                  <TextLink
                    href={`https://goerli.etherscan.io/address/${contract?.testNetContract?.hash}`}
                    target="_blank"
                  >
                    {formatMessage({
                      id: 'campaignDashboardPage.contentCard.testContract.secondState.secondLink',
                    })}
                  </TextLink>
                </TextLinkWrapper>
              </LinksWrapper>
              {!contract?.liveContract?.hash && (
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => setConfirmationStep(true)}
                  data-testid="retest-button"
                  disabled={
                    Boolean(contract?.liveContract?.hash) || contract?.liveContract?.isPublishing
                  }
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.testContract.firstState.button',
                  })}
                </Button>
              )}
            </>
          )}
      </Card>
    </CardWrapper>
  );
};

export default TestContract;
