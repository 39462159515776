import axios from 'axios';

const getCampaignService = async (
  baseUrl: string,
  bearerToken: string,
  id: string
): Promise<any> => {
  return axios.get(`${baseUrl}campaign/${id}`, { headers: { authorization: bearerToken } });
};

export { getCampaignService };
