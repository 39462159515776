import { Network } from '../../../pages/Home/hooks/useGenerateContract/types';

const walletValidator = (wallets: string[], network: string): void => {
  const networkRegex = findNetworkRegex(network);

  wallets.forEach((wallet) => {
    const isValidWallet = wallet.match(networkRegex);

    if (isValidWallet == null) throw new Error(`Invalid ${network} wallet`);
  });
};

const findNetworkRegex = (network: string): RegExp => {
  if (network === Network.ETHEREUM) {
    return /^0x[a-fA-F0-9]{40}$/g;
  }
  throw new Error('Invalid network! Could not validate wallets.');
};

export { walletValidator };
