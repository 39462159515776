import { useCallback, useContext } from 'react';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';

import getApiHost from '../../../../services/host/HostService';
import { getCampaignService } from '../../../../shared-components/CampaignFormAggregator/services/getCampaign/getCampaign';
import { CampaignContext } from '../../../../utils/context/campaignContext/campaign.context';

const useGetCampaignById = (id: string): VoidFunction => {
  const { setCampaignRequest, setCampaignSuccess, setCampaignError } = useContext(CampaignContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(async () => {
    try {
      setCampaignRequest();
      const campaignResponse = await getCampaignService(getApiHost(), BEARERTOKEN, id);
      setCampaignSuccess([campaignResponse.data]);
    } catch (error) {
      setCampaignError(error.response.status);
    }
  }, [setCampaignRequest, setCampaignSuccess, setCampaignError]);
};

export { useGetCampaignById };
