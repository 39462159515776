import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import CancelUploadDialog from '../CancelUploadDialog/CancelUploadDialog';
import { SetupStepIcon } from '../SetupStepIcon/SetupStepIcon';

import { CampaignStepperProps } from './types';

export const stepsData = [
  {
    label: 'campaignFormAggregator.stepper.firstStep.label',
    description: 'campaignFormAggregator.stepper.firstStep.description',
  },
  {
    label: 'campaignFormAggregator.stepper.secondStep.label',
    description: 'campaignFormAggregator.stepper.secondStep.description',
  },
  {
    label: 'campaignFormAggregator.stepper.thirdStep.label',
    description: 'campaignFormAggregator.stepper.thirdStep.description',
  },
  {
    label: 'campaignFormAggregator.stepper.fourthStep.label',
    description: 'campaignFormAggregator.stepper.fourthStep.description',
  },
];

const CampaignStepper: React.FC<CampaignStepperProps> = ({
  formStep,
  setStep,
  goToStep,
  setGoToStep,
  abortControllerRef,
}) => {
  const { formatMessage } = useIntl();
  const { isCampaignFormValid, isUploading } = useCampaignFormContext();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState(false);

  const handleCancelUploadDialogOpen = () => {
    setIsCancelUploadDialogOpen(!isCancelUploadDialogOpen);
  };

  const handleStep = (index: number): void => {
    if (isCampaignFormValid && isUploading) {
      setGoToStep(index);
      handleCancelUploadDialogOpen();
    }

    if (isCampaignFormValid && !isUploading) {
      setStep(index);
    }
  };

  return (
    <Stepper orientation="vertical" activeStep={formStep} data-testid="campaignStepper">
      {stepsData.map((step, index) => (
        <Step
          key={step.label}
          data-active={`${index === formStep ? 'active' : 'unactive'}`}
          data-completed={`${index >= formStep ? 'uncompleted' : 'completed'}`}
        >
          <StepLabel
            onClick={() => handleStep(index)}
            StepIconComponent={(props) => SetupStepIcon({ ...props, step: index + 1 })}
            data-testid={`step-${index}`}
          >
            {formatMessage({
              id: step.label,
            })}
          </StepLabel>
          <StepContent>
            {formatMessage({
              id: step.description,
            })}
          </StepContent>
        </Step>
      ))}
      <CancelUploadDialog
        isOpen={isCancelUploadDialogOpen}
        setIsOpen={handleCancelUploadDialogOpen}
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        setStep={setStep}
        title="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageTitle"
        description="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageDescription"
        leftButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.leaveButton"
        rightButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelButton"
        abortControllerRef={abortControllerRef}
      />
    </Stepper>
  );
};

export default CampaignStepper;
