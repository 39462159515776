import { useContext, useMemo } from 'react';
import { CampaignContext } from '../../../../utils/context/campaignContext/campaign.context';

export const useCampaignId = (id: string) => {
  const { campaigns } = useContext(CampaignContext);

  const campaign = useMemo(() => {
    return campaigns.find((campaignElement) => campaignElement.id === id);
  }, [campaigns]);
  return campaign;
};
