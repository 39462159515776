import { styled } from '@bees/vision-tokens';
import { Checkmark3 } from '@hexa-ui/icons';

export const ContainerWrapper = styled('div', {
  position: 'relative',

  width: '30%',
  height: '100%',

  '.disabled > *': {
    color: '$neutral50',
  },
});

export const Header = styled('div', {
  padding: '$10 0',
  marginLeft: '$16',

  '.disabled > *': {
    color: '$neutral50',
  },

  h1: {
    fontSize: '3rem',
  },

  p: {
    marginTop: '$1',
  },
});

export const Position = styled('div', {
  position: 'absolute',
  left: '0',

  marginTop: '$2',

  svg: {
    width: '2.5rem',
    height: '2.5rem',
  },
});

export const Checkmark = styled(Checkmark3, {
  marginTop: '$2',

  '& circle': {
    fill: '$semanticSuccessBasis',
  },
  '& path': {
    color: '$neutral0',
  },
});

export const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '& > div:first-of-type': {
    marginBottom: '$8',
  },
});
