import styled from 'styled-components';

type AllowListWrapperProps = {
  isVisible: boolean;
};

export const AllowListWrapper = styled.div<AllowListWrapperProps>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  min-height: 190px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AllowListWalletCount = styled.span``;
export const AllowListLastUploaded = styled.span``;
