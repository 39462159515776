import axios from 'axios';
import { ICampaign } from '../../../../models/campaignModel';

const editCampaignService = async (
  baseUrl: string,
  bearerToken: string,
  campaign: ICampaign
): Promise<any> => {
  return axios.patch(`${baseUrl}campaign/${campaign.id}`, campaign, {
    headers: { authorization: bearerToken },
  });
};

export { editCampaignService };
