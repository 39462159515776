import { CustomizedCard } from '../../../../../shared-components/SharedGrid';
import { CustomHeading } from '../../../../../shared-components/Typography/Typography';
import CardField from '../CardField/CardField';
import EditCard from '../EditCard/EditCard';
import { campaignPropsType, collectionPropsType, mintingPropsType } from './types';

interface ReviewCardProps {
  props: campaignPropsType | collectionPropsType | mintingPropsType;
  cardTitle: string;
  step: number;
  setStep: (step: number) => void;
  css?: any;
}

const ReviewCard = ({ props, step, cardTitle, setStep, css }: ReviewCardProps): JSX.Element => {
  return (
    <>
      <CustomizedCard
        border="medium"
        elevated="minimal"
        padding="25px 35px"
        gap="10px"
        position="relative"
        data-testid="reviewCard"
        css={css}
      >
        <CustomHeading type="H3" css={{ margin: '0 0 10px 0' }}>
          {cardTitle}
        </CustomHeading>
        {Object.entries(props)
          .filter(([_, value]) => value)
          .map(([key, value]: any, idx: number) => {
            return <CardField key={idx} title={key} description={value} />;
          })}
        <EditCard step={step} setStep={setStep} />
      </CustomizedCard>
    </>
  );
};

export default ReviewCard;
