import styled from 'styled-components';

export const EditWrapper = styled.button`
  position: absolute;
  top: 25px;
  right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 8px;
  padding: 4px;

  cursor: pointer;
  border-radius: 8px;
  border: none;
  outline: none;
  background: none;

  transition: color 0.2s;

  &:hover {
    p {
      color: rgb(167, 167, 167);
    }
  }
`;
