import { Edit2 } from '@admin-portal-shared-components/icons';
import { CustomParagraph } from '../../../../../shared-components/Typography/Typography';
import { EditWrapper } from '../ReviewForm.styles';

interface EditCardProps {
  step: number;
  setStep: (step: number) => void;
}

const EditCard = ({ step, setStep }: EditCardProps): JSX.Element => {
  return (
    <EditWrapper onClick={() => setStep(step)} data-testid="editcard">
      <Edit2 size="large" />
      <CustomParagraph type="basis" weight="medium" css={{ margin: '0' }}>
        Edit
      </CustomParagraph>
    </EditWrapper>
  );
};

export default EditCard;
