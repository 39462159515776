import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    title: 'Test title',
    description: 'Test description',
    button: 'Test button',
    translate: 'Translate',
    missing: 'Only English option available',
  },
  homePage: {
    title: 'Manage campaigns',
    expandedTable: {
      publishSettings: {
        title: 'Publishing settings',
        firstDescription: 'Interact with the campaign',
        secondaryDescription: 'smart contract and NFT collection.',
        modalFirstState: {
          primaryButton: 'Cancel',
          secondaryButton: 'Start',
          triggerButton: 'Start campaign',
          firstTitle: 'Are you sure you want to start',
          secondaryTitle: 'this campaign?',
          firstDescription: 'Starting',
          secondaryDescription: ' will change its status.',
        },
        modalSecondaryState: {
          primaryButton: 'Cancel',
          secondaryButton: 'Edit now',
          triggerButton: 'Start campaign',
          title: 'Start Campaign',
          description: 'To start this campaign you must first define the',
        },
      },
      expandedCard: {
        testContract: {
          firstState: {
            header: 'Test contract',
            firstDescription: 'Set up a smart contract',
            secondDescription: 'into Goerli testnet.',
            thirdDescription: 'You cannot test your smart contract anymore.',
            button: 'Test',
          },
          secondState: {
            header: 'Test contract',
            description: 'Published on: ',
            firstLinkText: 'Copy contract hash to clipboard',
            secondLinkText: 'Open in Etherscan',
            button: 'Test',
          },
          confirmation: {
            header: 'Test contract',
            firstDescription: 'Set up a new version of the',
            secondDescription: 'smart contract into Goerli testnet?',
            primaryButton: 'Cancel',
            secondaryButton: 'OK',
          },
          loadingText: 'Deploying contract into testnet, ',
          loadingSecondText: 'this may take some time.',
          toastMessage: 'Test contract deployed successfully!',
        },
        publishContract: {
          firstState: {
            header: 'Publish contract',
            firstDescription: 'Publish the campaign smart',
            secondDescription: 'contract into Ethereum mainnet.',
            button: 'Publish',
          },
          secondState: {
            header: 'Ethereum contract',
            description: 'Published on: ',
            firstLinkText: 'Copy contract hash to clipboard',
            secondLinkText: 'Open in Etherscan',
            button: 'Publish',
          },
          confirmation: {
            header: 'Publishing cannot be reversed.',
            headerWithoutTest: 'Contract has not been tested yet.',
            description: 'Publish smart contract into mainnet?',
            primaryButton: 'Cancel',
            secondaryButton: 'Publish',
          },
          loadingText: 'Deploying contract into mainnet, ',
          loadingSecondText: 'this may take some time.',
          toastMessage: 'Main contract deployed successfully!',
          errors: {
            contractDeployFailed: 'Contract deploy failed. Try again later.',
            liveContractAlreadyPublished: 'Live contract already published',
            alreadyDeployingContract:
              'This campaign is already deploying a contract. Wait a few seconds and try again.',
          },
        },
        uploadCollection: {
          firstState: {
            header: 'Upload collection',
            firstDescription: 'Upload the campaign',
            secondDescription: 'NFT collection into IPFS.',
            button: 'Upload',
          },
          secondState: {
            header: 'IPFS collection',
            description: 'Published on IPFS',
            button: 'Upload',
          },
          confirmation: {
            header: 'Upload cannot be reversed.',
            description: 'Upload NFT collection into IPFS?',
            primaryButton: 'Cancel',
            secondaryButton: 'Upload',
          },
          loadingText: 'Uploading collection into IPFS, ',
          loadingSecondText: 'this may take some time.',
          toastMessage: 'Collection uploaded to IPFS successfully!',
          errors: {
            ipfsAlreadyUploaded: 'Collection already uploaded to IPFS.',
            collectionNotFound: 'Collection not found. Upload it first.',
            contractNotPublished: 'Contract should be published first.',
          },
        },
      },
    },
  },
  newCampaignPage: {
    title: 'Create new campaign',
  },
  campaignDashboardPage: {
    contentCard: {
      testContract: {
        header: 'Test contract',
        firstState: {
          description: 'Set up a smart contract into Goerli testnet.',
          publishedContractDescription: 'You cannot test your smart contract anymore.',
          button: 'Test',
        },
        secondState: {
          description: 'Published on: ',
          firstLink: 'Copy contract hash to clipboard',
          secondLink: 'Open in Etherscan',
        },
        confirmation: {
          description: 'Set up a new version of the smart contract into Goerli testnet?',
          primaryButton: 'Cancel',
          secondaryButton: 'OK',
        },
        loadingMessage: 'Deploying contract into testnet, this may take some time.',
        toastMessage: 'Test contract deployed successfully!',
      },
      publishContract: {
        header: 'Publish contract',
        firstState: {
          description: 'Publish the campaign smart contract into Ethereum mainnet.',
          button: 'Publish',
        },
        secondState: {
          description: 'Published on: ',
          firstLink: 'Copy contract hash to clipboard',
          secondLink: 'Open in Etherscan',
        },
        confirmation: {
          alert: 'Publishing cannot be reversed.',
          alertWithoutTest: 'Contract has not been tested yet.',
          description: 'Publish smart contract into mainnet?',
          primaryButton: 'Cancel',
          secondaryButton: 'Publish',
        },
        loadingMessage: 'Deploying contract into mainnet, this may take some time.',
        toastMessage: 'Main contract deployed successfully!',
        errors: {
          contractDeployFailed: 'Contract deploy failed. Try again later.',
          liveContractAlreadyPublished: 'Live contract already published',
          alreadyDeployingContract:
            'This campaign is already deploying a contract. Wait a few seconds and try again.',
        },
      },
      uploadToIpfs: {
        header: 'Art to IPFS',
        firstState: {
          description: 'Upload the campaign NFT collection to IPFS',
          button: 'Upload art',
        },
        secondState: {
          description: 'Uploaded on IPFS',
          link: 'Show collection',
        },
        confirmation: {
          alert: 'Upload cannot be reversed.',
          description: 'Upload NFT collection to IPFS?',
          primaryButton: 'Cancel',
          secondaryButton: 'Upload',
        },
        loadingMessage: 'Uploading collection to IPFS, this may take some time.',
        toastMessage: 'Collection uploaded to IPFS successfully!',
        errors: {
          ipfsAlreadyUploaded: 'Collection already uploaded to IPFS.',
          collectionNotFound: 'Collection not found. Upload it first.',
          contractNotPublished: 'Contract should be published first.',
        },
      },
      claiming: {
        header: 'Claiming page',
        description: 'Preview the single page website for the NFT Collection claims.',
        button: 'Show page',
      },
      openSeaCollection: {
        header: 'OpenSea collection',
        firstState: {
          description: 'Insert the brand campaign collection on OpenSea.',
          button: 'Add page',
        },
        secondState: {
          description: 'Added on: ',
          link: 'Show on OpenSea',
        },
        confirmation: {
          inputPlaceholder: 'https://opensea.io/collection/',
          primaryButton: 'Cancel',
          secondaryButton: 'Add',
        },
        loadingMessage: 'Adding collection to OpenSea, this may take some time.',
        toastMessage: 'Collection added to OpenSea successfully!',
        errors: {
          pageAlreadyAdded: 'Collection already added to OpenSea.',
          collectionNotFound: 'Collection not found. Upload it first.',
          contractNotPublished: 'Contract should be published first.',
        },
      },
      setupShopify: {
        header: 'Set up Shopify',
        firstState: {
          description: 'Send the campaign data for marketplace configuration.',
          button: 'Send data',
        },
        secondState: {
          description: 'Sent on: ',
          link: 'Open the online store',
        },
        confirmation: {
          description: 'Send the collection information and price per NFT to Shopify?',
          primaryButton: 'Cancel',
          secondaryButton: 'Send',
        },
        loadingMessage: 'Sending information to Shopify, this may take some time.',
        toastMessage: 'Information sent to Shopify successfully!',
        errors: {
          dataAlreadySent: 'Data already sent to Shopify.',
          collectionNotFound: 'Collection not found. Upload it first.',
          contractNotPublished: 'Contract should be published first.',
          generic: 'Something went wrong. Try again.',
        },
      },
    },
    tabs: {
      campaignInfo: {
        label: 'Campaign Info',
        id: 'campaign-info',
      },
      basicSetup: {
        label: 'Basic Setup',
        id: 'basic-setup',
      },
    },
  },
  errorPage: {
    404: {
      buttonText: 'Home',
      headerText: 'Mmm… Looks like this page is gone',
      subheaderText: 'It looks like this page wasn’t found or doesn’t exist.',
    },
    500: {
      headerText: 'Ooops! Something went wrong',
    },
    503: {
      buttonText: 'Try again',
      headerText: 'Hm... Service unavailable!',
      subheaderText:
        'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
      infoText: 'If the issue persists, you can report the problem.',
    },
  },
  breadcrumb: {
    homePage: 'Manage campaigns',
    createCampaignPage: 'New Campaign',
  },
  campaignFormAggregator: {
    campaignForm: {
      labels: {
        brand: 'Brand',
        url: 'Brand NFT website',
        name: 'Title',
        description: 'Description',
        symbol: 'Symbol',
        launchDate: 'Launch Date',
        nftBasePrice: 'Price per NFT',
        optional: 'Optional',
        presale: {
          title: 'Pre-sale campaign',
          basePrice: 'Base price',
          startDate: 'Start Date',
          endDate: 'End date',
        },
      },
      placeholders: {
        brand: 'Select',
        url: 'https://',
        name: 'Campaign name',
        description: 'Explain the campaign',
        symbol: 'e.g. ABI',
      },
      allowList: {
        title: 'Pre-sale Allowlist',
        description: 'Drop CSV file here to start uploading or',
        loadingMsg: 'Uploading file',
        walletCount: 'Number of wallets',
        lastUploaded: 'Uploaded',
        editMsg: 'Edit the current file by downloading and replacing it.',
        buttons: {
          download: 'Download',
          replace: 'Replace',
          browseFiles: 'Browse files',
        },
      },
      buttons: {
        addAllowList: 'Add Allowlist',
        editAllowList: 'Edit Allowlist',
        downloadAllowList: 'Download Allowlist',
        back: 'Back',
        cancel: 'Cancel',
        next: 'Next',
        createCampaign: 'Create Campaign',
        saveCampaign: 'Save Campaign',
      },
      errors: {
        requiredField: 'Required field',
        valueGreaterThanZero: 'The value must be greater than 0',
        invalidValue: 'Invalid value',
        invalidDate: 'Date not valid',
        launchDateMinDate: 'Should be greater than pre-sale end date',
        endDateMinDate: 'Should be greater than pre-sale start date',
        noPastDates: 'Date should not be in the past',
      },
    },
    collectionForm: {
      title: 'Collection files',
      description: 'For each art file, add also a metadata file, .json',
      upload: {
        title: 'Uploading files',
        description: 'This can take some time',
        cancelButton: 'Cancel Upload',
      },
      cancelUploadDialog: {
        cancelUploadTitle: 'Are you sure you want to cancel the upload?',
        leavePageTitle: 'Are you sure you want to leave the page?',
        cancelUploadDescription: 'You will lose the current collection files',
        leavePageDescription: 'You will cancel the current collection upload',
        cancelButton: 'Cancel',
        keepUploadButton: 'Keep Uploading',
        leaveButton: 'Leave',
      },
      totalFiles: 'Total number of files',
      totalNumberOfNfts: 'Total number of NFTs',
      lastUploaded: 'Uploaded',
      buttons: {
        download: 'Download Collection',
        replace: 'Replace Folder',
        browseFolder: 'Browse Folder',
      },
      toast: {
        cancelledMessage: 'Collection upload cancelled',
        uploadFailed: 'Error while uploading files',
        invalidJsonFileName: 'Invalid JSON file name',
      },
    },
    mintingForm: {
      timing: {
        title: 'Timing',
        preMint: 'Pre mint',
        preMintDescription: 'Mint the collection before the launch date.',
        postMint: 'Post mint',
        postMintDescription: 'Mint the NFT into the buyers wallet.',
      },
      blockchain: {
        title: 'Blockchain',
        ethereum: 'Ethereum',
      },
      gasFeeCap: {
        title: 'Gas Fee Cap (not in contract)',
      },
      buttons: {
        addMinters: 'Add minters',
        editMinters: 'Edit minters',
        download: 'Download Minters List',
      },
      allowlist: {
        title: 'Minters Allowlist',
      },
      toast: {
        csvFormatNotValid: 'CSV is not formatted as expected',
        invalidWallet: 'CSV contains invalid wallet',
        csvUploadError: 'Could not upload CSV',
      },
    },
    stepper: {
      firstStep: {
        label: 'Campaign',
        description: 'Detail the NFT drop',
      },
      secondStep: {
        label: 'Collection',
        description: 'NFT collection artwork',
      },
      thirdStep: {
        label: 'Minting',
        description: 'Contract minting settings',
      },
      fourthStep: {
        label: 'Review',
        description: 'Summary of the campaign',
      },
    },
    actionButtons: {
      toast: {
        editCampaign: {
          success: 'Your changes were successfully saved',
        },
      },
    },
  },
};

export default enUS;
