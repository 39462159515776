import { styled } from '@hexa-ui/theme';

export const CardWrapper = styled('div', {
  display: 'flex',

  '.card': {
    display: 'flex',
    position: 'relative',

    minHeight: '23vh',
    width: '100%',

    padding: '$8',
    gap: '$2',
  },

  '.disabled > *': {
    color: '$neutral50',
  },

  '.icon': {
    position: 'absolute',
    right: '$8',
    top: '$8',

    width: '3rem',
    height: '3rem',
  },

  '.longer': {
    maxWidth: '40ch',
  },

  h3: {
    margin: '$2 0',
  },

  p: {
    maxWidth: '27ch',
  },

  button: {
    alignSelf: 'flex-end',
    width: 'fit-content',

    padding: '0 $8',
    marginTop: '$1',
  },
});

export const ConfirmationWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',

  '.alert': {
    width: '100%',
    padding: '$4',

    div: {
      fontSize: '$3',
    },
  },
});

export const LoadingWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  height: '100%',

  p: {
    textAlign: 'center',
    marginBottom: '$2',
  },
});

export const LinksWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const TextLinkWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});

export const ButtonsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});
