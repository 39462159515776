import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Package } from '@hexa-ui/icons';
import { OpenInNew } from '@material-ui/icons';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { formattedDate } from '../../../../../shared-components/FormattedDate/formatted-date';
import { useCampaignId } from '../../../../Home/hooks/useCampaignId/useCampaignId';
import { CardContainerStatus } from '../../CardContainer/types';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { OpenSeaProps } from '../types';
import { confirmationConfig } from './config';

const OpenSeaCollection = ({ id, status }: OpenSeaProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const campaignData = useCampaignId(id);
  const { data } = confirmationConfig();
  const { formatMessage } = useIntl();
  const isDisabled = status === CardContainerStatus.DISABLED;

  /* istanbul ignore next */
  const handleClick = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <CardWrapper data-testid="opensea-collection">
      <Card border="medium" elevated="small" className={isDisabled ? 'card disabled' : 'card'}>
        {!isLoading && (
          <>
            <Package size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.openSeaCollection.header',
              })}
            </Heading>
          </>
        )}

        {!confirmationStep && !campaignData.nftStorage.uploaded && !isLoading && (
          <>
            <Paragraph size="basis">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.openSeaCollection.firstState.description',
              })}
            </Paragraph>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => setConfirmationStep(true)}
              data-testid="add-button"
              disabled={isDisabled}
            >
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.openSeaCollection.firstState.button',
              })}
            </Button>
          </>
        )}

        {confirmationStep && (
          <Confirmation
            props={data}
            setConfirmationStep={setConfirmationStep}
            setIsConfirmed={setIsConfirmed}
            request={handleClick}
          />
        )}

        {isLoading && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.openSeaCollection.loadingMessage',
            })}
          />
        )}

        {campaignData.nftStorage.uploaded && (
          <>
            <Paragraph size="basis" className="longer">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.openSeaCollection.secondState.description',
              })}
              {campaignData.nftStorage.uploadDate &&
                formattedDate(campaignData.nftStorage.uploadDate.toString(), 'day')}
            </Paragraph>
            <LinksWrapper data-testid="opensea-links-wrapper">
              <TextLinkWrapper>
                <OpenInNew />
                <TextLink
                  href="https://opensea.io/collection/collectionName"
                  target="_blank"
                  size="basis"
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.openSeaCollection.secondState.link',
                  })}
                </TextLink>
              </TextLinkWrapper>
            </LinksWrapper>
          </>
        )}
      </Card>
    </CardWrapper>
  );
};

export default OpenSeaCollection;
