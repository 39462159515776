export const formattedDate = (date?: string, format?: 'number' | 'day'): string => {
  let dateToReturn = '';
  if (!date) {
    return dateToReturn;
  }

  if (format === 'number') {
    const locale = 'en-US';
    const currentDate = new Date(date);
    const dateString = currentDate.toLocaleDateString(locale);
    const timeString = currentDate.toLocaleTimeString(locale, { timeStyle: 'short' });
    dateToReturn = dateString + ' - ' + timeString;
  }
  if (format === 'day') {
    const locale = 'en-US';
    const currentDate = new Date(date);
    const dateString = currentDate.toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const timeString = currentDate.toLocaleTimeString(locale, { timeStyle: 'short' });
    dateToReturn = dateString + ' at ' + timeString;
  }

  return dateToReturn;
};

export const formatDateToEnUs = (date: string): string => {
  if (!date || date.length === 0) return '';
  return new Date(date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};
