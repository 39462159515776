import { Button } from '@admin-portal-shared-components/button';
import { Dialog } from '@admin-portal-shared-components/dialog';
import { LoadingDots } from '@admin-portal-shared-components/spinner';
import { Heading, Paragraph } from '@admin-portal-shared-components/typography';
import { Grid } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { DropzoneArea } from 'material-ui-dropzone';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatDateToEnUs } from '../../../../shared-components/FormattedDate/formatted-date';

import { FileUploadIcon } from '../../../../shared-components/icons/FileUploadIcon';
import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useAddMinters } from '../../hooks/useAddMinters/useAddMinters';
import { useDownloadAllowlist } from '../../hooks/useDownloadAllowlist/useDownloadAllowlist';
import { useSaveCampaign } from '../../hooks/useSaveCampaign/useSaveCampaign';
import { AllowListLastUploaded, AllowListWalletCount, AllowListWrapper } from './Allowlist.styles';

type AllowListTypes = {
  isOpen: boolean;
  setIsOpen: () => void;
  isMinters: boolean;
  title: string;
};

const Allowlist: React.FC<AllowListTypes> = ({ isOpen, setIsOpen, isMinters, title }) => {
  const { formatMessage } = useIntl();
  const { campaignForm } = useCampaignFormContext();
  const [isUploading, setIsUploading] = useState(false);
  const [shouldEdit, setShouldEdit] = useState(false);
  const [shouldDisplayCsvData, setShouldDisplayCsvData] = useState<boolean>();
  const addMinters = useAddMinters();
  const editCampaign = useSaveCampaign();
  const downloadAllowlist = useDownloadAllowlist();
  const toastService = useToast();

  useEffect(() => {
    if (isMinters) {
      campaignForm.minting.allowList.length
        ? setShouldDisplayCsvData(true)
        : setShouldDisplayCsvData(false);
    } else {
      campaignForm.preSale.allowList.length
        ? setShouldDisplayCsvData(true)
        : setShouldDisplayCsvData(false);
    }
  }, [isOpen, campaignForm.minting.allowList, campaignForm.preSale.allowList]);

  useEffect(() => {
    if (shouldEdit) {
      editCampaign();
      setIsUploading(false);
      setShouldEdit(false);
    }
  }, [shouldEdit]);

  const handleCsv = async (files: File[]) => {
    try {
      setIsUploading(true);

      if (!isMinters) {
        throw new Error('Presale CSV upload not implemented yet');
      }

      await addMinters(files[0]);

      setShouldEdit(true);
    } catch (error) {
      handleToastErrorMessage(error.message);

      setIsUploading(false);
    }
  };

  const handleToastErrorMessage = (errorMessage: string) => {
    switch (errorMessage) {
      case 'CSV format not valid':
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({
            id: 'campaignFormAggregator.mintingForm.toast.csvFormatNotValid',
          }),
        });
        break;

      case 'Invalid ethereum wallet':
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: 'campaignFormAggregator.mintingForm.toast.invalidWallet' }),
        });
        break;

      default:
        toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: 'campaignFormAggregator.mintingForm.toast.csvUploadError' }),
        });
        break;
    }
  };

  const handleDownloadCsv = async () => {
    const csvFile = await downloadAllowlist(true);

    const csvFileUrl = window.URL.createObjectURL(csvFile);

    window.open(csvFileUrl, 'download');
  };

  return (
    <AllowListWrapper isVisible={isOpen} data-testid="allowListDialog">
      <Dialog
        onClose={setIsOpen}
        open={isOpen}
        title={<Heading size="H3">{formatMessage({ id: title })}</Heading>}
      >
        <AllowListWrapper
          isVisible={!isUploading && !shouldDisplayCsvData}
          data-testid="allowListDropzoneArea"
        >
          <DropzoneArea
            onDrop={handleCsv}
            dropzoneText={formatMessage({
              id: 'campaignFormAggregator.campaignForm.allowList.description',
            })}
            acceptedFiles={['text/csv']}
            showAlerts={false}
            showPreviewsInDropzone={false}
            filesLimit={1}
            maxFileSize={10000000}
            inputProps={{ id: 'fileUploader' }}
            disableRejectionFeedback={true}
            //@ts-ignore
            Icon={FileUploadIcon}
          />
          <Button variant="secondary" style={{ position: 'absolute', bottom: '90px' }}>
            {formatMessage({
              id: 'campaignFormAggregator.campaignForm.allowList.buttons.browseFiles',
            })}
          </Button>
        </AllowListWrapper>
        <AllowListWrapper isVisible={isUploading} data-testid="uploadSpinner">
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Paragraph size="small">
                {formatMessage({ id: 'campaignFormAggregator.campaignForm.allowList.loadingMsg' })}
              </Paragraph>
            </Grid>
            <Grid item>
              <LoadingDots size="xlarge" />
            </Grid>
          </Grid>
        </AllowListWrapper>

        <AllowListWrapper isVisible={!isUploading && shouldDisplayCsvData} data-testid="fileInfo">
          <Grid container direction="column" style={{ marginBottom: '20px' }}>
            <Grid item>
              <Paragraph size="small">
                <AllowListWalletCount id="walletCount">
                  {formatMessage({
                    id: 'campaignFormAggregator.campaignForm.allowList.walletCount',
                  })}
                  :{' '}
                  {isMinters
                    ? campaignForm.minting.allowList.length
                    : campaignForm.preSale.allowList.length}
                </AllowListWalletCount>
                <br />
                <AllowListLastUploaded id="lastUploaded">
                  {formatMessage({
                    id: 'campaignFormAggregator.campaignForm.allowList.lastUploaded',
                  })}
                  :{' '}
                  {isMinters
                    ? formatDateToEnUs(campaignForm.minting?.uploadDate?.toString())
                    : formatDateToEnUs(campaignForm.preSale?.uploadDate?.toString())}
                </AllowListLastUploaded>
              </Paragraph>
            </Grid>
            <Grid item>
              <Paragraph size="small">
                {formatMessage({ id: 'campaignFormAggregator.campaignForm.allowList.editMsg' })}
              </Paragraph>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item>
              <Button
                onClick={handleDownloadCsv}
                variant="secondary"
                size="medium"
                data-testid="allowlistDownloadButton"
              >
                {formatMessage({
                  id: 'campaignFormAggregator.campaignForm.allowList.buttons.download',
                })}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setShouldDisplayCsvData(false)}
                variant="secondary"
                size="medium"
                data-testid="allowlistReplaceButton"
              >
                {formatMessage({
                  id: 'campaignFormAggregator.campaignForm.allowList.buttons.replace',
                })}
              </Button>
            </Grid>
          </Grid>
        </AllowListWrapper>
      </Dialog>
    </AllowListWrapper>
  );
};

export default Allowlist;
