import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Upload } from '@hexa-ui/icons';
import { FileCopyOutlined, OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { formattedDate } from '../../../../../shared-components/FormattedDate/formatted-date';
import { useCampaignId } from '../../../../Home/hooks/useCampaignId/useCampaignId';
import { Network } from '../../../../Home/hooks/useGenerateContract/types';
import { useGenerateContract } from '../../../../Home/hooks/useGenerateContract/useGenerateContract';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { PublishContractProps } from '../types';
import { confirmationConfig } from './config';

const PublishContract = ({ id }: PublishContractProps): JSX.Element => {
  const generateContract = useGenerateContract(id, true, Network.GOERLI);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const contract = useCampaignId(id);
  const { data } = confirmationConfig(Boolean(contract?.testNetContract?.hash));
  const { formatMessage } = useIntl();
  const toastService = useToast();

  const handleClick = async () => {
    setIsLoading(true);
    const errorMessage = await generateContract();
    errorMessage
      ? toastService.notify({ type: TypeToast.ERROR, message: formatMessage({ id: errorMessage }) })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.contentCard.publishContract.toastMessage',
          }),
        });
    setIsLoading(false);
  };

  return (
    <CardWrapper data-testid="publish-contract">
      <Card border="medium" elevated="small" className="card">
        {!isLoading && !confirmationStep && (
          <>
            <Upload size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.publishContract.header',
              })}
            </Heading>
          </>
        )}

        {!contract?.liveContract?.isPublishing &&
          !contract?.liveContract?.hash &&
          !confirmationStep &&
          !isLoading && (
            <>
              <Paragraph size="basis">
                {formatMessage({
                  id: 'campaignDashboardPage.contentCard.publishContract.firstState.description',
                })}
              </Paragraph>
              <Button
                variant="secondary"
                size="medium"
                onClick={() => setConfirmationStep(true)}
                data-testid="publish-button"
                disabled={contract?.testNetContract?.isPublishing}
              >
                {formatMessage({
                  id: 'campaignDashboardPage.contentCard.publishContract.firstState.button',
                })}
              </Button>
            </>
          )}

        {confirmationStep && (
          <Confirmation
            props={data}
            setConfirmationStep={setConfirmationStep}
            setIsConfirmed={setIsConfirmed}
            request={handleClick}
          />
        )}

        {(contract?.liveContract?.isPublishing || (isLoading && isConfirmed)) && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.publishContract.loadingMessage',
            })}
          />
        )}

        {!contract?.liveContract?.isPublishing && contract?.liveContract?.hash && (
          <>
            <Paragraph size="basis" className="longer">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.publishContract.secondState.description',
              })}
              {contract?.liveContract?.publishedDate &&
                formattedDate(contract?.liveContract?.publishedDate.toString(), 'day')}
            </Paragraph>
            <LinksWrapper data-testid="publish-links-wrapper">
              <CopyToClipboard text={contract?.liveContract && contract?.liveContract?.hash}>
                <TextLinkWrapper>
                  <FileCopyOutlined />
                  <TextLink>
                    {formatMessage({
                      id: 'campaignDashboardPage.contentCard.publishContract.secondState.firstLink',
                    })}
                  </TextLink>
                </TextLinkWrapper>
              </CopyToClipboard>
              <TextLinkWrapper>
                <OpenInNew />
                <TextLink
                  href={`https://goerli.etherscan.io/address/${contract?.liveContract?.hash}`}
                  target="_blank"
                >
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.publishContract.secondState.secondLink',
                  })}
                </TextLink>
              </TextLinkWrapper>
            </LinksWrapper>
          </>
        )}
      </Card>
    </CardWrapper>
  );
};

export default PublishContract;
