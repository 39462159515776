import { ICampaignContext } from './campaign.types';

export const initialState: ICampaignContext = {
  isLoadingCampaign: true,
  campaigns: [],
  campaignError: null,
  setCampaignRequest: () => undefined,
  setCampaignSuccess: () => undefined,
  setCampaignError: () => undefined,
};

export enum ICampaignAction {
  CAMPAIGN_REQUEST = 'CAMPAIGN_REQUEST',
  CAMPAIGN_SUCCESS = 'CAMPAIGN_SUCCESS',
  CAMPAIGN_ERROR = 'CAMPAIGN_ERROR',
}
