import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { editCampaignService } from '../../services/editCampaign/editCampaign';

const useSaveCampaign = (): Function => {
  const { campaignForm, setCampaignFormRequest, setCampaignFormSuccess, setCampaignFormError } =
    useContext(CampaignFormContext);
  const { BEARERTOKEN } = useEnvContext();
  return useCallback(async (): Promise<boolean> => {
    const { inputErrors, ...campaign } = campaignForm;
    try {
      setCampaignFormRequest();
      await editCampaignService(getApiHost(), BEARERTOKEN, {
        ...campaign,
        status: campaign.status === 'Draft' ? 'Open' : campaign.status,
      });
      setCampaignFormSuccess({
        ...campaignForm,
        inputErrors: {
          brand: '',
          campaign: '',
          externalUrl: '',
          campaignSymbol: '',
          nftBasePrice: '',
          preSaleBasePrice: '',
        },
      });
      return true;
    } catch (error) {
      setCampaignFormError(error.response.status);
      return false;
    }
  }, [campaignForm, setCampaignFormRequest, setCampaignFormError]);
};

export { useSaveCampaign };
