import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { ICampaignForm } from '../../../models/campaignFormModel';
import { ICampaignFormAction, initialState } from './campaignForm.actions';
import { campaignFormReducer } from './campaignForm.reducer';
import { ICampaignFormContext } from './campaignForm.types';

interface ICampaignFormContextProps {
  children: ReactNode;
}

export const CampaignFormContext = createContext<ICampaignFormContext>(initialState);

const CampaignFormContextProvider = ({ children }: ICampaignFormContextProps) => {
  const [state, dispatch] = useReducer(campaignFormReducer, initialState);

  const setCampaignFormRequest = useCallback(() => {
    dispatch({
      type: ICampaignFormAction.CAMPAIGN_FORM_REQUEST,
      payload: null,
    });
  }, []);

  const setCampaignFormSuccess = useCallback((campaignFormData: ICampaignForm) => {
    dispatch({
      type: ICampaignFormAction.CAMPAIGN_FORM_SUCCESS,
      payload: campaignFormData,
    });
  }, []);

  const setCampaignFormError = useCallback((error: number) => {
    dispatch({
      type: ICampaignFormAction.CAMPAIGN_FORM_ERROR,
      payload: error,
    });
  }, []);

  const setIsCampaignFormValid = useCallback((isValid: boolean) => {
    dispatch({
      type: ICampaignFormAction.CAMPAIGN_FORM_VALIDATION,
      payload: isValid,
    });
  }, []);

  const setIsUploading = useCallback((isUploading: boolean) => {
    dispatch({
      type: ICampaignFormAction.CAMPAIGN_FORM_UPLOADING,
      payload: isUploading,
    });
  }, []);

  const campaignValue = useMemo((): ICampaignFormContext => {
    return {
      campaignForm: state.campaignForm,
      isLoading: state.isLoading,
      campaignFormError: state.campaignFormError,
      isCampaignFormValid: state.isCampaignFormValid,
      isUploading: state.isUploading,
      setCampaignFormRequest,
      setCampaignFormSuccess,
      setCampaignFormError,
      setIsCampaignFormValid,
      setIsUploading,
    };
  }, [
    state.campaignForm,
    state.isLoading,
    state.campaignFormError,
    state.isCampaignFormValid,
    state.isUploading,
    setCampaignFormRequest,
    setCampaignFormSuccess,
    setCampaignFormError,
    setIsCampaignFormValid,
    setIsUploading,
  ]);

  return (
    <CampaignFormContext.Provider value={campaignValue}>{children}</CampaignFormContext.Provider>
  );
};
export const useCampaignFormContext = () => {
  return useContext(CampaignFormContext);
};
export default React.memo(CampaignFormContextProvider);
