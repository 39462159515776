import { useCallback } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { shopifyService } from '../../services/exportShopify/shopify';

export interface IShopifyResponse {
  success: boolean;
  errors?: {
    status: number;
    message: { name: string };
  };
}
type Hook = () => (campaignId: string) => Promise<IShopifyResponse>;

const useExportShopifyDataHook: Hook = () => {
  const { BEARERTOKEN } = useEnvContext();
  return useCallback(
    async (campaignId: string) => {
      try {
        await shopifyService(getApiHost(), BEARERTOKEN, campaignId);
        return {
          success: true,
        };
      } catch (error: any) {
        return {
          success: false,
          errors: {
            status: error.response.status,
            message: error.response.data,
          },
        };
      }
    },
    [BEARERTOKEN]
  );
};

export { useExportShopifyDataHook };
