import { Tabs } from '@hexa-ui/components';
const { Root, List, Trigger, Content } = Tabs;

import { TabsProps } from './types';

const TabsComponent = ({ defaultTab, tabs }: TabsProps) => {
  return (
    <>
      <Root defaultValue={defaultTab} data-testid="tabs-component">
        <List>
          {tabs?.map((tab) => (
            <Trigger data-testid={`${tab.id}-tab`} value={tab.id} key={tab.id}>
              {tab.label}
            </Trigger>
          ))}
        </List>
        {tabs?.map((tab) => (
          <Content
            data-testid={`${tab.id}-content`}
            value={tab.id}
            key={tab.id}
            style={{ backgroundColor: 'transparent' }}
          >
            {tab.content()}
          </Content>
        ))}
      </Root>
    </>
  );
};

export default TabsComponent;
