import { Divider } from '@hexa-ui/components';
import { DividerWrapper } from './DividerComponent.styles';

interface DividerComponentProps {
  orientation: 'horizontal' | 'vertical';
}

const DividerComponent: React.FC<DividerComponentProps> = ({ orientation }) => {
  return (
    <DividerWrapper data-testid="DividerComponent" data-orientation={orientation}>
      <Divider orientation={orientation} />
    </DividerWrapper>
  );
};

export default DividerComponent;
