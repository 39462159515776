import { ICampaign } from '../../../../models/campaignModel';
import { CardContainerStatus } from '../CardContainer/types';
import { CardProps } from './types';

export type GetCardPropsResult = {
  contractProps: CardProps;
  marketplaceProps: CardProps;
  collectionProps: CardProps;
};

export const getCardProps = (
  { id, liveContract, ipfs }: ICampaign,
  shopifyConfig = false // TO BE DEFINED ON NFTCORE-161
): GetCardPropsResult => {
  let contractProps: CardProps = {
    id: id,
    status: CardContainerStatus.ENABLED,
  };
  let marketplaceProps: CardProps = {
    id: id,
    status: CardContainerStatus.DISABLED,
  };
  let collectionProps: CardProps = {
    id: id,
    status: CardContainerStatus.DISABLED,
  };

  if (liveContract?.hash) contractProps.status = CardContainerStatus.COMPLETED;

  if (contractProps.status == CardContainerStatus.COMPLETED)
    marketplaceProps.status = CardContainerStatus.ENABLED;

  // TO BE DEFINED ON NFTCORE-161
  if (shopifyConfig) marketplaceProps.status = CardContainerStatus.COMPLETED;

  if (marketplaceProps.status == CardContainerStatus.COMPLETED)
    collectionProps.status = CardContainerStatus.ENABLED;

  if (ipfs?.uploaded) collectionProps.status = CardContainerStatus.COMPLETED;

  return {
    contractProps,
    marketplaceProps,
    collectionProps,
  };
};
