import { createTheme } from '@material-ui/core';

export const campaignFormAggregatorTheme = createTheme({
  typography: {
    fontFamily: '"Work Sans", "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
  },
  overrides: {
    // Stepper
    MuiStepper: {
      root: {
        padding: '35px',
      },
    },
    MuiStep: {
      root: {
        fontFamily: '"Work Sans", "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
      },
    },
    MuiStepLabel: {
      root: {
        '& span': {
          fontSize: 20,
        },
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    MuiStepContent: {
      root: {
        marginLeft: '17px',
        paddingLeft: '25px',
      },
    },
    MuiStepConnector: {
      vertical: {
        marginLeft: '17px',
      },
    },

    // DatePicker inputs
    MuiInputLabel: {
      root: {
        fontSize: '1.1em',
        color: 'var(--colors-interfaceLabelPrimary)',
        fontWeight: 'bold',
        position: 'initial',
        lineHeight: 'var(--lineHeights-3)',
        paddingBottom: '0.1em',
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: '0',
      },
      notchedOutline: {
        borderColor: 'rgba(0,0,0,0.15)',
      },
      root: {
        order: 1,
        width: '100%',
        height: '48px',
        borderRadius: '0.5rem',
        borderColor: 'rgba(0,0,0,0.15)',
        '&:hover $notchedOutline': {
          borderColor: 'rgba(0,0,0,0.15)',
          borderWidth: '1px',
        },
        '&$focused $notchedOutline': {
          borderColor: '#000',
          borderWidth: '1px',
        },
        '&$error $notchedOutline': {
          borderColor: 'var(--colors-semanticErrorText)',
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: '0',
        marginRight: '0',
      },
      root: {
        marginTop: '0',
        fontSize: 'var(--fontSizes-2)',
        fontWeight: 'normal',
        '&$error': {
          color: 'var(--colors-semanticErrorText)',
        },
      },
    },
    // DatePicker Dialog calendar
    MuiPaper: {
      elevation0: {
        background: 'none',
      },
      elevation24: {
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
      },
    },
    MuiDialogActions: {
      root: {
        '& button': {
          display: 'none',
        },
      },
    },
    // @ts-ignore
    MuiPickersDay: {
      day: {
        color: '#000',
      },
      daySelected: {
        color: '#fff',
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: '#000',
          color: '#fff',
        },
      },
      current: {
        color: '#000',
        backgroundColor: '#f2f2f2',
      },
    },
    MuiDropzoneArea: {
      textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& p': {
          order: 2,
        },
      },
      root: {
        zIndex: '1',
        backgroundColor: 'none',
        height: '320px',
        padding: '55px',
        marginTop: '50px',
        borderColor: '#047AF1',
        border: '1px dashed',
        borderRadius: '16px',
        '& p': {
          fontSize: '0.8rem',
        },
        '&.collectionDropzone': {
          marginTop: '0',
        },
      },
    },
  },
});
