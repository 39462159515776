import { ICampaignForm } from '../../../models/campaignFormModel';
import { ICampaignFormContext } from './campaignForm.types';

export const campaignFormInitialState: ICampaignForm = {
  id: '',
  launchDate: '',
  brand: '',
  campaign: '',
  campaignDescription: '',
  campaignSymbol: '',
  externalUrl: '',
  nftBasePrice: '',
  status: 'Draft',
  preSale: {
    enable: false,
    startDate: '',
    endDate: '',
    basePrice: '',
    allowList: [],
  },
  minting: {
    blockChain: 'Ethereum',
    postMinting: true,
    gasFeeCap: 75,
    allowList: [],
  },
  nftStorage: {
    uploaded: false,
    baseUri: '',
  },
  preSaleAllowListFile: [],
  collectionFile: [],
  mintingAllowListFile: [],
  inputErrors: {
    brand: '',
    campaign: '',
    externalUrl: '',
    campaignSymbol: '',
    nftBasePrice: '',
  },
};

export const initialState: ICampaignFormContext = {
  isLoading: true,
  campaignForm: campaignFormInitialState,
  campaignFormError: null,
  isCampaignFormValid: false,
  isUploading: false,
  setCampaignFormRequest: () => undefined,
  setCampaignFormSuccess: () => undefined,
  setCampaignFormError: () => undefined,
  setIsCampaignFormValid: () => undefined,
  setIsUploading: () => undefined,
};

export enum ICampaignFormAction {
  CAMPAIGN_FORM_REQUEST = 'CAMPAIGN_FORM_REQUEST',
  CAMPAIGN_FORM_SUCCESS = 'CAMPAIGN_FORM_SUCCESS',
  CAMPAIGN_FORM_ERROR = 'CAMPAIGN_FORM_ERROR',
  CAMPAIGN_FORM_VALIDATION = 'CAMPAIGN_FORM_VALIDATION',
  CAMPAIGN_FORM_UPLOADING = 'CAMPAIGN_FORM_UPLOADING',
}
