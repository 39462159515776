import { BlobItem, BlobServiceClient } from '@azure/storage-blob';
import { useCallback, useContext } from 'react';
import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { deleteCollection } from '../../services/deleteCollection/deleteCollection';

const useDeleteCollection = (): Function => {
  const { campaignForm } = useContext(CampaignFormContext);
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();

  return useCallback(
    async (sasToken: string, blobItem: BlobItem, abortControllerSignal?: AbortSignal) => {
      try {
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobItem.name);

        await deleteCollection(blockBlobClient, abortControllerSignal);
      } catch (error) {
        console.info(error);
      }
    },
    [campaignForm]
  );
};

export { useDeleteCollection };
