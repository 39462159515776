import { Grid } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
const { Container } = Grid;

import CampaignFormAggregator from '../../shared-components/CampaignFormAggregator/CampaignFormAggregator';
import TabsComponent from '../../shared-components/Tabs/TabsComponent';
import { TabsProps } from '../../shared-components/Tabs/types';
import BasicSetupWrapper from './components/BasicSetupWrapper/BasicSetupWrapper';
import { useGetCampaignById } from './hooks/useGetCampaignById/useGetCampaignById';

const CampaignDashboardPage = () => {
  const { id, campaign } = useParams();
  const getCampaign = useGetCampaignById(id);
  const [_, setAppHeaderConfig] = useAppHeader();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: campaign,
      breadcrumbConfig: {
        homePath: '/',
        items: [
          {
            label: formatMessage({ id: 'breadcrumb.homePage' }),
            path: '/nftcore',
            isCurrentPage: false,
          },
          {
            label: campaign,
            path: `/nftcore/campaign/${id}/dashboard/${campaign}`,
            isCurrentPage: true,
          },
        ],
      },
    });
  }, [setAppHeaderConfig]);

  useEffect(() => {
    getCampaign();
  }, []);

  const dashboardTabs: TabsProps = {
    defaultTab: formatMessage({ id: 'campaignDashboardPage.tabs.campaignInfo.id' }),
    tabs: [
      {
        label: formatMessage({ id: 'campaignDashboardPage.tabs.campaignInfo.label' }),
        id: formatMessage({ id: 'campaignDashboardPage.tabs.campaignInfo.id' }),
        content: () => <CampaignFormAggregator />,
      },
      {
        label: formatMessage({ id: 'campaignDashboardPage.tabs.basicSetup.label' }),
        id: formatMessage({ id: 'campaignDashboardPage.tabs.basicSetup.id' }),
        content: () => <BasicSetupWrapper id={id} />,
      },
    ],
  };

  return (
    <Container type="fluid" sidebar data-testid="campaign-dashboard-page">
      <TabsComponent {...dashboardTabs} />
    </Container>
  );
};

export default CampaignDashboardPage;
