import { Button } from '@admin-portal-shared-components/button';
import { Divider } from '@admin-portal-shared-components/divider';
import { ProgressMeter } from '@admin-portal-shared-components/progress-meter';
import { Paragraph } from '@admin-portal-shared-components/typography';
import { Grid } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatDateToEnUs } from '../../../../shared-components/FormattedDate/formatted-date';
import { FolderUploadIcon } from '../../../../shared-components/icons/FolderUploadIcon';

import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useDeleteCollection } from '../../hooks/useDeleteCollection/useDeleteCollection';
import { useGetSasToken } from '../../hooks/useGetSasToken/useGetSasToken';
import { StorageOperations } from '../../hooks/useGetSasToken/useGetSasToken.types';
import { useListCollection } from '../../hooks/useListCollection/useListCollection';
import { useSaveCampaign } from '../../hooks/useSaveCampaign/useSaveCampaign';
import { useUploadCollection } from '../../hooks/useUploadCollection/useUploadCollection';
import CancelUploadDialog from '../CancelUploadDialog/CancelUploadDialog';
import { CollectionWrapper } from './CollectionForm.styles';

type CollectionFormProps = {
  setStep: (step: number) => void;
  goToStep: number;
  setGoToStep: (step: number) => void;
  abortControllerRef?: MutableRefObject<AbortController>;
};

const CollectionForm: React.FC<CollectionFormProps> = ({
  setStep,
  goToStep,
  setGoToStep,
  abortControllerRef,
}): JSX.Element => {
  const acceptedFileTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'video/mp4',
    'video/webm',
    'application/json',
  ];

  const { campaignForm, isUploading, setIsUploading } = useCampaignFormContext();
  const getSasToken = useGetSasToken();
  const [uploadProgress, setUploadProgress] = useState(0);
  const listCollection = useListCollection();
  const deleteCollection = useDeleteCollection();
  const uploadCollection = useUploadCollection();
  const editCampaign = useSaveCampaign();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState(false);
  const { formatMessage } = useIntl();
  const [shouldEdit, setShouldEdit] = useState(false);
  const toastService = useToast();

  useEffect(() => {
    if (shouldEdit) {
      editCampaign();
      setIsUploading(false);
      setUploadProgress(0);
      setShouldEdit(false);
    }
  }, [shouldEdit]);

  const handleUploadCollection = async (files: File[]) => {
    try {
      setIsUploading(true);

      const listSasToken = await getSasToken(StorageOperations.LIST);
      const collectionItems = await listCollection(listSasToken);

      for await (const blobItem of collectionItems) {
        const deleteSasToken = await getSasToken(StorageOperations.DELETE);
        await deleteCollection(deleteSasToken, blobItem);
      }

      const uploadSasToken = await getSasToken(
        StorageOperations.UPLOAD,
        abortControllerRef.current.signal
      );
      await uploadCollection(
        files,
        uploadSasToken,
        setUploadProgress,
        abortControllerRef.current.signal
      );

      setShouldEdit(true);
      abortControllerRef.current = new AbortController();
    } catch (error) {
      /* istanbul ignore next */
      if (abortControllerRef.current.signal.aborted) {
        toastService.notify({
          type: TypeToast.WARNING,
          message: formatMessage({
            id: 'campaignFormAggregator.collectionForm.toast.cancelledMessage',
          }),
        });
      } else {
        error.message === 'Invalid JSON file name!'
          ? toastService.notify({
              type: TypeToast.ERROR,
              message: formatMessage({
                id: 'campaignFormAggregator.collectionForm.toast.invalidJsonFileName',
              }),
            })
          : toastService.notify({
              type: TypeToast.ERROR,
              message: formatMessage({
                id: 'campaignFormAggregator.collectionForm.toast.uploadFailed',
              }),
            });
      }

      setIsUploading(false);
      setUploadProgress(0);
      abortControllerRef.current = new AbortController();
      const listSasToken = await getSasToken(StorageOperations.LIST);
      const collectionItems = await listCollection(listSasToken);

      for await (const blobItem of collectionItems) {
        const deleteSasToken = await getSasToken(StorageOperations.DELETE);
        await deleteCollection(deleteSasToken, blobItem);
      }
      setShouldEdit(true);
    }
  };

  const handleCancelUploadDialogOpen = () => {
    setIsCancelUploadDialogOpen(!isCancelUploadDialogOpen);
  };

  return (
    <>
      <Grid container direction="column" data-testid="collectionForm">
        <Grid item sm={10} md={8} lg={5} xl={4}>
          <Paragraph size="small" weight="medium">
            {formatMessage({ id: 'campaignFormAggregator.collectionForm.title' })}
          </Paragraph>
          <CollectionWrapper isVisible={!isUploading} data-testid="dropzoneWrapper">
            <DropzoneArea
              onDrop={(files) => handleUploadCollection(files)}
              dropzoneText={formatMessage({
                id: 'campaignFormAggregator.collectionForm.description',
              })}
              acceptedFiles={acceptedFileTypes}
              showAlerts={false}
              showPreviewsInDropzone={false}
              filesLimit={100000}
              maxFileSize={100000000}
              inputProps={{ id: 'fileUploader' }}
              dropzoneClass="collectionDropzone"
              disableRejectionFeedback={true}
              //@ts-ignore
              Icon={FolderUploadIcon}
            />
            <Button
              variant="secondary"
              style={{
                position: 'relative',
                marginLeft: 'auto',
                marginRight: 'auto',
                bottom: '85px',
              }}
              data-testid="dropzoneButton"
            >
              {campaignForm.nftStorage?.uploaded
                ? formatMessage({ id: 'campaignFormAggregator.collectionForm.buttons.replace' })
                : formatMessage({
                    id: 'campaignFormAggregator.collectionForm.buttons.browseFolder',
                  })}
            </Button>
          </CollectionWrapper>
          <CollectionWrapper
            isVisible={isUploading}
            style={{
              height: '320px',
              border: '1px solid #000',
              borderRadius: '16px',
            }}
            data-testid="uploadCard"
          >
            <Grid item>
              <Paragraph size="small">
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.upload.title' })}
              </Paragraph>
            </Grid>
            <Grid item>
              <ProgressMeter maxValue={101} value={uploadProgress} css={{ width: '160px' }} />
            </Grid>
            <Grid item>
              <Paragraph size="xsmall">
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.upload.description' })}
              </Paragraph>
            </Grid>
            <Button
              onClick={handleCancelUploadDialogOpen}
              variant="secondary"
              data-testid="cancelButton"
            >
              {formatMessage({ id: 'campaignFormAggregator.collectionForm.upload.cancelButton' })}
            </Button>
          </CollectionWrapper>
        </Grid>
        {!isUploading && campaignForm.nftStorage?.uploaded && (
          <Grid item sm={10} md={8} lg={5} xl={4} data-testid="uploadedFileData">
            <Divider />
            <Paragraph size="small">
              <span>
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.totalFiles' })}:{' '}
                {campaignForm.nftStorage?.filesCount}
              </span>
              <br />
              <span>
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.totalNumberOfNfts' })}:{' '}
                {campaignForm.nftStorage?.nftQuantity}
              </span>
              <br />
              <span>
                {formatMessage({ id: 'campaignFormAggregator.collectionForm.lastUploaded' })}:{' '}
                {formatDateToEnUs(campaignForm.nftStorage?.uploadDate)}
              </span>
            </Paragraph>
          </Grid>
        )}
      </Grid>
      <CancelUploadDialog
        isOpen={isCancelUploadDialogOpen}
        setIsOpen={handleCancelUploadDialogOpen}
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        setStep={setStep}
        title="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelUploadTitle"
        description="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelUploadDescription"
        leftButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelButton"
        rightButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.keepUploadButton"
        abortControllerRef={abortControllerRef}
      />
    </>
  );
};

export default CollectionForm;
