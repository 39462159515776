import { ReactNode } from 'react';

export const enum CardContainerStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  COMPLETED = 'COMPLETED',
}

export interface CardContainerProps {
  children: ReactNode;
  status: CardContainerStatus;
  title: string;
  description: string;
  position: number;
}
