import { Button } from '@admin-portal-shared-components/button';
import { Divider } from '@admin-portal-shared-components/divider';
import { DocumentSigned, Trash2 } from '@admin-portal-shared-components/icons';
import { Input } from '@admin-portal-shared-components/input';
import { Toggle } from '@admin-portal-shared-components/toggle';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  currencyBlurHandler,
  currencyFocusHandler,
  currencyFormatter,
} from '../../../../shared-components/FormattedCurrency/formattedCurrency';
import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { validateCurrency } from '../../../../utils/validators/currencyValidator/currencyValidator';

import Allowlist from '../Allowlist/Allowlist';

const Presale = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isAllowListOpen, setIsAllowListOpen] = useState(false);
  const [preSaleBasePrice, setPreSaleBasePrice] = useState(null);
  const { campaignForm, setCampaignFormSuccess } = useCampaignFormContext();
  const startDate = new Date(campaignForm.preSale.startDate);
  const validEndDate = new Date(startDate);
  validEndDate.setDate(validEndDate.getDate() + 1);

  const handleAllowList = () => {
    setIsAllowListOpen(!isAllowListOpen);
  };

  const handlePresale = () => {
    setCampaignFormSuccess({
      ...campaignForm,
      preSale: {
        ...campaignForm.preSale,
        enable: !campaignForm.preSale.enable,
      },
    });
  };

  const dateHandler = (date: Date, name: string) => {
    setCampaignFormSuccess({
      ...campaignForm,
      preSale: {
        ...campaignForm.preSale,
        [name]: date.toISOString(),
      },
    });
  };

  const basePriceHandler = (e: React.ChangeEvent<any>) => {
    const price = e.target.value.replace(/[^0-9.]+/g, '');

    const errorMessage = validateCurrency(price);

    setPreSaleBasePrice(price);
    setCampaignFormSuccess({
      ...campaignForm,
      preSale: {
        ...campaignForm.preSale,
        basePrice: price,
      },
      inputErrors: {
        ...campaignForm.inputErrors,
        preSaleBasePrice: errorMessage ? formatMessage({ id: errorMessage }) : '',
      },
    });
  };

  const preSaleBasePriceValue = (): string => {
    if (preSaleBasePrice == null) {
      return campaignForm.preSale.basePrice
        ? currencyFormatter.format(Number(campaignForm.preSale.basePrice))
        : '';
    }
    return preSaleBasePrice;
  };

  return (
    <Grid item style={{ marginTop: '15px' }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <InputLabel shrink>
            {formatMessage({ id: 'campaignFormAggregator.campaignForm.labels.presale.title' })}
          </InputLabel>
        </Grid>
        <Grid item>
          <Toggle
            checked={campaignForm.preSale.enable}
            onClick={handlePresale}
            data-testid="toggleButton"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Divider css={{ marginTop: '10px', marginBottom: '20px' }} />
      </Grid>
      <Grid
        container
        spacing={3}
        direction="column"
        style={{ display: campaignForm.preSale.enable ? 'flex' : 'none' }}
        data-testid="preSaleContainer"
      >
        <Grid item>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Input
                width="100%"
                name="preSaleBasePrice"
                label={formatMessage({
                  id: 'campaignFormAggregator.campaignForm.labels.presale.basePrice',
                })}
                placeholder={'$'}
                value={preSaleBasePriceValue()}
                onFocus={(e) => currencyFocusHandler(e, setPreSaleBasePrice)}
                onBlur={(e) => currencyBlurHandler(e, setPreSaleBasePrice)}
                onChange={(e) => basePriceHandler(e)}
                hasError={!!campaignForm.inputErrors.preSaleBasePrice}
                errorText={campaignForm.inputErrors.preSaleBasePrice}
                data-testid="preSaleBasePrice"
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', marginBottom: '4px' }}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Button
                    onClick={handleAllowList}
                    variant="secondary"
                    data-testid="preSaleAllowListButton"
                  >
                    {campaignForm.preSale.allowList.length === 0
                      ? formatMessage({
                          id: 'campaignFormAggregator.campaignForm.buttons.addAllowList',
                        })
                      : formatMessage({
                          id: 'campaignFormAggregator.campaignForm.buttons.editAllowList',
                        })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="secondary"
                    leading
                    icon={DocumentSigned}
                    style={{
                      display: campaignForm.preSale.allowList.length === 0 ? 'none' : 'flex',
                    }}
                    data-testid="preSaleDownloadAllowListButton"
                  >
                    {formatMessage({
                      id: 'campaignFormAggregator.campaignForm.buttons.downloadAllowList',
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3} alignItems="flex-end" justifyContent="center">
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InputLabel shrink>
                  {formatMessage({
                    id: 'campaignFormAggregator.campaignForm.labels.presale.startDate',
                  })}
                </InputLabel>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  variant="inline"
                  InputAdornmentProps={{ position: 'start' }}
                  invalidDateMessage={formatMessage({
                    id: 'campaignFormAggregator.campaignForm.errors.invalidDate',
                  })}
                  format="MM/dd/yyyy"
                  autoOk
                  disablePast
                  disableToolbar
                  value={campaignForm.preSale.startDate || null}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <button
                        data-testid="reset-button-startdate"
                        onClick={() => {
                          setCampaignFormSuccess({
                            ...campaignForm,
                            preSale: {
                              ...campaignForm.preSale,
                              startDate: '',
                            },
                          });
                        }}
                        style={{
                          cursor: 'pointer',
                          background: 'none',
                          border: 'none',
                          padding: 0,
                        }}
                      >
                        <Trash2 size="large" />
                      </button>
                    ),
                  }}
                  onChange={(date) => dateHandler(date, 'startDate')}
                  data-testid="preSaleStartDate"
                  minDate={new Date()}
                  minDateMessage={formatMessage({
                    id: 'campaignFormAggregator.campaignForm.errors.noPastDates',
                  })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InputLabel shrink>
                  {formatMessage({
                    id: 'campaignFormAggregator.campaignForm.labels.presale.endDate',
                  })}
                </InputLabel>
                <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  variant="inline"
                  InputAdornmentProps={{ position: 'start' }}
                  invalidDateMessage={formatMessage({
                    id: 'campaignFormAggregator.campaignForm.errors.invalidDate',
                  })}
                  format="MM/dd/yyyy"
                  autoOk
                  disablePast
                  disableToolbar
                  value={campaignForm.preSale.endDate || null}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <button
                        data-testid="reset-button-enddate"
                        onClick={() => {
                          setCampaignFormSuccess({
                            ...campaignForm,
                            preSale: {
                              ...campaignForm.preSale,
                              endDate: '',
                            },
                          });
                        }}
                        style={{
                          cursor: 'pointer',
                          background: 'none',
                          border: 'none',
                          padding: 0,
                        }}
                      >
                        <Trash2 size="large" />
                      </button>
                    ),
                  }}
                  onChange={(date) => dateHandler(date, 'endDate')}
                  data-testid="preSaleEndDate"
                  minDate={validEndDate || new Date()}
                  minDateMessage={formatMessage({
                    id: 'campaignFormAggregator.campaignForm.errors.endDateMinDate',
                  })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Allowlist
        isOpen={isAllowListOpen}
        setIsOpen={handleAllowList}
        isMinters={false}
        title="campaignFormAggregator.campaignForm.allowList.title"
      />
    </Grid>
  );
};

export default Presale;
