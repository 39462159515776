import { useCallback, useContext } from 'react';
import { IWalletInfo } from '../../../../models/campaignModel';
import { Network } from '../../../../pages/Home/hooks/useGenerateContract/types';
import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { allowlistCsvValidator } from '../../../../utils/validators/allowlistCsvValidator/allowlistCsvValidator';
import { walletValidator } from '../../../../utils/validators/walletValidator/walletValidator';

const useAddMinters = (): Function => {
  const { campaignForm, setCampaignFormSuccess } = useContext(CampaignFormContext);

  return useCallback(
    async (file: File): Promise<void> => {
      try {
        const csvContent = await allowlistCsvValidator(file);

        walletValidator(csvContent, Network.ETHEREUM);

        const allowListArray = csvContent.map((item: string): IWalletInfo => {
          return Object.assign({
            walletAddress: item,
          });
        });

        setCampaignFormSuccess({
          ...campaignForm,
          minting: {
            ...campaignForm.minting,
            allowList: allowListArray,
            uploadDate: new Date().toISOString(),
          },
        });
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [campaignForm, setCampaignFormSuccess]
  );
};

export { useAddMinters };
