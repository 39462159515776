const generateContractErrorMapper = (errorName: string): string => {
  if (errorName.includes('Live contract already published'))
    return 'homePage.expandedTable.expandedCard.publishContract.errors.liveContractAlreadyPublished';

  if (errorName.includes('This campaign is already deploying a contract'))
    return 'homePage.expandedTable.expandedCard.publishContract.errors.alreadyDeployingContract';

  return 'homePage.expandedTable.expandedCard.publishContract.errors.contractDeployFailed';
};

export { generateContractErrorMapper };
