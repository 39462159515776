export const FolderUploadIcon = () => {
  return (
    <svg
      width="114"
      height="101"
      viewBox="0 0 114 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1781_75435)">
        <rect width="94" height="94" rx="16" fill="#D1F7F6" />
        <mask
          id="mask0_1781_75435"
          /* style="mask-type:alpha" */ maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="94"
          height="94"
        >
          <rect width="94" height="94" rx="16" fill="white" />
        </mask>
        <g mask="url(#mask0_1781_75435)">
          <path
            d="M75.25 41.375H69.625C68.5895 41.375 67.75 40.4105 67.75 39.375V33.75H26.25V39.375C26.25 40.4105 25.4105 41.375 24.375 41.375H18.75C16.6789 41.375 15 39.5711 15 37.5V20.625C15 17.5184 17.5184 15 20.625 15H32C36.5264 15 40.3166 18.2264 41.1882 22.5H73.375C76.4816 22.5 79 25.0184 79 28.125V37.5C79 39.5711 77.3211 41.375 75.25 41.375Z"
            fill="#F1CD76"
          />
          <path
            d="M69.625 41.375H75.25C77.3211 41.375 79 39.5709 79 37.5V28.125C79 25.0184 76.4816 22.5 73.375 22.5H47V33.75H67.75V39.375C67.75 40.4105 68.5895 41.375 69.625 41.375Z"
            fill="#DAB868"
          />
          <path
            d="M69.625 41.375H24.375C23.3386 41.375 22.5 40.4114 22.5 39.375V31.875C22.5 30.8386 23.3386 30 24.375 30H69.625C70.6614 30 71.5 30.8386 71.5 31.875V39.375C71.5 40.4114 70.6614 41.375 69.625 41.375Z"
            fill="#FFF5F5"
          />
          <path
            d="M73.375 79H20.625C17.5184 79 15 76.4816 15 73.375V37.5H47.5L79 73.375C79 76.4816 76.4816 79 73.375 79Z"
            fill="#FFDC89"
          />
          <path
            d="M47 41.375H69.625C70.6614 41.375 71.5 40.4114 71.5 39.375V31.875C71.5 30.8386 70.6614 30 69.625 30H47V41.375Z"
            fill="#EFE2DD"
          />
          <path d="M79 37.5H47V79H73.375C76.4816 79 79 76.4816 79 73.375V37.5Z" fill="#F7CE6D" />
        </g>
        <ellipse cx="92.4886" cy="79.6119" rx="21.4369" ry="21.3882" fill="white" />
        <mask
          id="mask1_1781_75435"
          /* style="mask-type:alpha" */ maskUnits="userSpaceOnUse"
          x="81"
          y="67"
          width="24"
          height="24"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.5745 67.1139C93.3975 67.0405 93.2035 67 93 67C92.7971 67 92.6036 67.0403 92.4271 67.1133C92.2497 67.1865 92.0835 67.2952 91.9393 67.4393L81.4393 77.9393L81.3146 78.0807C80.857 78.6691 80.8986 79.5199 81.4393 80.0607L81.5807 80.1854C82.1691 80.643 83.0199 80.6014 83.5607 80.0607L91.5 72.1223V89.5L91.5101 89.6749C91.5967 90.4209 92.2307 91 93 91C93.8284 91 94.5 90.3284 94.5 89.5V72.1223L102.439 80.0607L102.581 80.1854C103.169 80.643 104.02 80.6014 104.561 80.0607C105.146 79.4749 105.146 78.5251 104.561 77.9393L94.0607 67.4393C94.0166 67.3952 93.9697 67.3539 93.9204 67.3155L93.9193 67.3146C93.812 67.2311 93.6958 67.1642 93.5745 67.1139Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1781_75435)">
          <rect x="77" y="63" width="32" height="32" fill="#4ABEFF" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1781_75435">
          <rect width="113.926" height="101" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
