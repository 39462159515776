import React, { createContext, ReactNode, useCallback, useContext, useReducer } from 'react';
import { ICampaign } from '../../../models/campaignModel';
import { ICampaignAction, initialState } from './campaign.actions';
import { campaignReducer } from './campaign.reducer';
import { ICampaignContext } from './campaign.types';

interface ICampaignContextProps {
  children: ReactNode;
}

export const CampaignContext = createContext<ICampaignContext>(initialState);

const CampaignContextProvider = ({ children }: ICampaignContextProps) => {
  const [state, dispatch] = useReducer(campaignReducer, initialState);

  const setCampaignRequest = useCallback(() => {
    dispatch({
      type: ICampaignAction.CAMPAIGN_REQUEST,
      payload: null,
    });
  }, []);

  const setCampaignSuccess = useCallback((listCampaign: Array<ICampaign>) => {
    dispatch({
      type: ICampaignAction.CAMPAIGN_SUCCESS,
      payload: listCampaign,
    });
  }, []);

  const setCampaignError = useCallback((error: string) => {
    dispatch({
      type: ICampaignAction.CAMPAIGN_ERROR,
      payload: error,
    });
  }, []);

  const campaignValue = {
    campaigns: state.campaigns,
    isLoadingCampaign: state.isLoadingCampaign,
    campaignError: state.campaignError,
    setCampaignRequest,
    setCampaignSuccess,
    setCampaignError,
  };

  return <CampaignContext.Provider value={campaignValue}>{children}</CampaignContext.Provider>;
};
export const useCampaignContext = () => {
  return useContext(CampaignContext);
};
export default React.memo(CampaignContextProvider);
