const allowlistCsvValidator = async (file: File): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const csvAsText = e.target.result.toString();
        const csvArrayObj = await validateCsvFormat(csvAsText);
        resolve(csvArrayObj);
      } catch (error) {
        reject(error);
      }
    };
    /* istanbul ignore next */
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
};

const validateCsvFormat = async (csvAsText: string): Promise<string[]> => {
  try {
    const headers = csvAsText.slice(0, csvAsText.indexOf('\n')).split(',');
    const rows = csvAsText.slice(csvAsText.indexOf('\n') + 1).split('\n');

    let newHeader = headers[0];
    if (newHeader.includes('\r')) {
      newHeader = newHeader.slice(0, -1);
    }

    const isValid = newHeader.toLowerCase() === 'wallet' && rows !== undefined;
    if (!isValid) throw new Error('CSV format not valid');

    return rows.map((row: string) => {
      if (row.includes('\r')) {
        return row.slice(0, -1);
      }
      return row;
    });
  } catch (error) {
    throw new Error(error.message);
  }
};

export { allowlistCsvValidator };
