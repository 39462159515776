import { ICampaignForm } from '../../../../models/campaignFormModel';
import { currencyFormatter } from '../../../../shared-components/FormattedCurrency/formattedCurrency';
import { formatDateToEnUs } from '../../../../shared-components/FormattedDate/formatted-date';

export const config = (campaignForm: ICampaignForm) => {
  const campaignProps = {
    Brand: campaignForm.brand,
    Title: campaignForm.campaign,
    Description: campaignForm.campaignDescription,
    'Brand URL': campaignForm.externalUrl,
    'Price per NFT': currencyFormatter.format(Number(campaignForm.nftBasePrice)),
    Symbol: campaignForm.campaignSymbol,
    'Launch Date': formatDateToEnUs(campaignForm.launchDate),
  };

  const collectionProps = {
    'Files count': campaignForm.nftStorage.filesCount,
    'Number of NFTs': campaignForm.nftStorage.nftQuantity,
    'Upload date': formatDateToEnUs(campaignForm.nftStorage?.uploadDate?.toString()),
  };

  const mintingProps = {
    Timing: 'Post mint',
    BlockChain: campaignForm.minting.blockChain,
    'Gas fee': `$ ${campaignForm.minting.gasFeeCap.toFixed(2)}`,
    'Number of wallets': campaignForm.minting.allowList.length,
    Uploaded: formatDateToEnUs(campaignForm.minting?.uploadDate?.toString()),
  };

  if (campaignForm.preSale.enable) {
    return {
      campaignProps: {
        ...campaignProps,
        'PRE-SALE': campaignForm.preSale.enable,
        'Pre-sale base price': currencyFormatter.format(Number(campaignForm.preSale.basePrice)),
        'Pre-sale start date': formatDateToEnUs(campaignForm.preSale.startDate),
        'Pre-sale end date': formatDateToEnUs(campaignForm.preSale.endDate),
        'Pre-sale folder name': 'pre_sale.zip',
        'Pre-sale uploaded': 'Jul 10, 2022',
      },
      collectionProps,
      mintingProps,
    };
  }

  return {
    campaignProps,
    collectionProps,
    mintingProps,
  };
};
