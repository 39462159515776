import axios from 'axios';

export const shopifyService = async (
  baseUrl: string,
  bearerToken: string,
  id: string
): Promise<void> => {
  return axios.post(
    `${baseUrl}shopify/campaign/info/${id}`,
    {},
    {
      headers: { authorization: bearerToken },
    }
  );
};
