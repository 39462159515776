import { BlockBlobClient } from '@azure/storage-blob';

const uploadFile = async (
  blockBlobClient: BlockBlobClient,
  file: File,
  abortControllerSignal: AbortSignal
): Promise<void> => {
  await blockBlobClient.uploadData(file, { abortSignal: abortControllerSignal });
};

export { uploadFile };
