const validateCollectionJson = (file: File): void => {
  const onlyNumbersRegex = /^\d+$/;
  let splittedFileName = file.name.split('.');
  splittedFileName.pop();
  for (const part of splittedFileName) {
    if (part.match(onlyNumbersRegex) == null) throw new Error('Invalid JSON file name!');
  }
};

export { validateCollectionJson };
