const uploadToIpfsErrorMapper = (errorName: string): string => {
  if (errorName.includes('ipfs already uploaded'))
    return 'homePage.expandedTable.expandedCard.uploadCollection.errors.ipfsAlreadyUploaded';
  if (errorName.includes('Collection not found'))
    return 'homePage.expandedTable.expandedCard.uploadCollection.errors.collectionNotFound';
  if (errorName.includes('Contract should be deployed first'))
    return 'homePage.expandedTable.expandedCard.uploadCollection.errors.contractNotPublished';
  return '';
};

export { uploadToIpfsErrorMapper };
