import { Button } from '@admin-portal-shared-components/button';
import { Grid } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { MutableRefObject, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { Steps } from '../../CampaignFormAggregator.types';
import { useSaveCampaign } from '../../hooks/useSaveCampaign/useSaveCampaign';
import CancelUploadDialog from '../CancelUploadDialog/CancelUploadDialog';

type ActionButtonsProps = {
  step: number;
  setStep: (step: number) => void;
  goToStep: number;
  setGoToStep: (step: number) => void;
  abortControllerRef?: MutableRefObject<AbortController>;
  isDashboard: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  step,
  setStep,
  setGoToStep,
  goToStep,
  abortControllerRef,
  isDashboard,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { isCampaignFormValid, isUploading, campaignForm } = useCampaignFormContext();
  const [isCancelUploadDialogOpen, setIsCancelUploadDialogOpen] = useState(false);
  const editCampaign = useSaveCampaign();
  const toastService = useToast();

  const shouldHideBackButton =
    isDashboard && (step === Steps.CAMPAIGN_STEP || step === Steps.OVERVIEW_STEP);

  const handleCancelUploadDialogOpen = () => {
    setIsCancelUploadDialogOpen(!isCancelUploadDialogOpen);
  };

  const decrementStep = (): void => {
    if (isUploading) {
      setGoToStep(Steps.CAMPAIGN_STEP);
      handleCancelUploadDialogOpen();
      return;
    }

    step === Steps.CAMPAIGN_STEP ? navigate('/nftcore') : setStep(--step);
  };

  const incrementStep = (): void => {
    if (isUploading) {
      setGoToStep(Steps.MINTING_STEP);
      handleCancelUploadDialogOpen();
      return;
    }

    step === Steps.OVERVIEW_STEP ? handleEditCampaign() : setStep(++step);
  };

  const handleEditCampaign = async () => {
    const result = await editCampaign();

    if (result) {
      if (campaignForm.status !== 'Draft') {
        toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignFormAggregator.actionButtons.toast.editCampaign.success',
          }),
        });
      }

      navigate(`/nftcore/campaign/${campaignForm.id}/dashboard/${campaignForm.campaign}?new=true`);
    }
  };

  const getButtonName = (status: string, formStep: Steps): string => {
    let name = formatMessage({
      id: 'campaignFormAggregator.campaignForm.buttons.next',
    });
    if (formStep === Steps.OVERVIEW_STEP) {
      name = formatMessage({
        id: 'campaignFormAggregator.campaignForm.buttons.saveCampaign',
      });
      if (status === 'Draft') {
        name = formatMessage({
          id: 'campaignFormAggregator.campaignForm.buttons.createCampaign',
        });
      }
    }
    return name;
  };

  return (
    <Grid item data-testid="actionButtons">
      <Grid container justifyContent={!shouldHideBackButton ? 'space-between' : 'flex-end'}>
        {!shouldHideBackButton && (
          <Button variant="secondary" data-testid="buttonLeft" onClick={decrementStep}>
            {step === Steps.CAMPAIGN_STEP ? (
              <>
                {formatMessage({
                  id: 'campaignFormAggregator.campaignForm.buttons.cancel',
                })}
              </>
            ) : (
              <>
                {formatMessage({
                  id: 'campaignFormAggregator.campaignForm.buttons.back',
                })}
              </>
            )}
          </Button>
        )}

        <Button
          variant="primary"
          data-testid="buttonRight"
          onClick={incrementStep}
          disabled={!isCampaignFormValid}
        >
          {getButtonName(campaignForm.status, step)}
        </Button>
      </Grid>
      <CancelUploadDialog
        isOpen={isCancelUploadDialogOpen}
        setIsOpen={handleCancelUploadDialogOpen}
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        setStep={setStep}
        title="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageTitle"
        description="campaignFormAggregator.collectionForm.cancelUploadDialog.leavePageDescription"
        leftButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.leaveButton"
        rightButtonText="campaignFormAggregator.collectionForm.cancelUploadDialog.cancelButton"
        abortControllerRef={abortControllerRef}
      />
    </Grid>
  );
};

export default ActionButtons;
