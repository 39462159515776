import axios from 'axios';
import { Network } from '../../hooks/useGenerateContract/types';

const generateContractService = async (
  baseUrl: string,
  bearerToken: string,
  id: string,
  isLiveContract: boolean,
  network?: Network
): Promise<any> => {
  return axios.post(
    `${baseUrl}campaign/${id}/contract`,
    { isLiveContract, network: network || 'goerli' },
    {
      headers: { authorization: bearerToken },
    }
  );
};

export { generateContractService };
