import { ThemeProvider } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { CustomizedCard, CustomizedGrid } from '../../shared-components/SharedGrid';
import { useCampaignFormContext } from '../../utils/context/campaignFormContext/campaignForm.context';
import { campaignFormAggregatorTheme } from './CampaignFormAggregator.styles';
import { Steps } from './CampaignFormAggregator.types';
import ActionButtons from './components/ActionButtons/ActionButtons';
import CampaignForm from './components/CampaignForm/CampaignForm';
import CampaignStepper from './components/CampaignStepper/CampaignStepper';
import CollectionForm from './components/CollectionForm/CollectionForm';
import MintingForm from './components/MintingForm/MintingForm';
import ReviewForm from './components/ReviewForm/ReviewForm';
import { useGetCampaign } from './hooks/useGetCampaign/useGetCampaign';
import { useValidateForm } from './hooks/useValidateForm/useValidateForm';

const CampaignFormAggregator = () => {
  const { campaignForm, isCampaignFormValid } = useCampaignFormContext();
  const [formStep, setFormStep] = useState<number>(Steps.CAMPAIGN_STEP);
  const [goToStep, setGoToStep] = useState<number>();
  const [shouldHideContent, setShouldHideContent] = useState<boolean>(true);
  const [shouldChangeStep, setShouldChangeStep] = useState<boolean>(true);
  const validateForm = useValidateForm(campaignForm);
  const { id, campaign } = useParams();
  const [searchParams] = useSearchParams();
  const isNewCampaign = searchParams.get('new');
  const getCampaign = useGetCampaign(id);
  let uploadCollectionAbortControllerRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    getCampaign();
  }, []);

  useEffect(() => {
    validateForm();

    if (!isCampaignFormValid && campaignForm.id) {
      setShouldHideContent(false);
    }

    if (
      (isCampaignFormValid && campaignForm.id && shouldChangeStep) ||
      (isNewCampaign && shouldChangeStep)
    ) {
      setFormStep(Steps.OVERVIEW_STEP);
      setShouldHideContent(false);
    }
  }, [campaignForm, isCampaignFormValid]);

  useEffect(() => {
    if (!shouldHideContent) {
      setShouldChangeStep(false);
    }
  }, [shouldHideContent]);

  return (
    <CustomizedGrid
      container
      justifyContent="center"
      width="100%"
      height="auto"
      data-testid="campaignFormAggregator"
      style={{ paddingBottom: '40px' }}
    >
      {!shouldHideContent && (
        <CustomizedCard
          borderradius="20px"
          marginleft={/* istanbul ignore next */ campaign ? '0px' : '108px'}
          marginright={/* istanbul ignore next */ campaign ? '0px' : '65px'}
          elevated="small"
          border="none"
          width="100%"
          data-testid="campaignFormAggregatorWrapper"
        >
          <ThemeProvider theme={campaignFormAggregatorTheme}>
            <CustomizedGrid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
              height="100%"
              background="#f2f2f2"
            >
              <CustomizedGrid item xs={4} width="auto" height="100%">
                <CampaignStepper
                  formStep={formStep}
                  setStep={setFormStep}
                  goToStep={goToStep}
                  setGoToStep={setGoToStep}
                  abortControllerRef={uploadCollectionAbortControllerRef}
                />
              </CustomizedGrid>
              <CustomizedGrid
                item
                xs={8}
                width="100%"
                height="100%"
                background="#fff"
                style={{ padding: '30px', minHeight: 'calc(100vh - 226px)' }}
              >
                <CustomizedGrid
                  container
                  direction="column"
                  justifyContent="space-between"
                  width="100%"
                  height="100%"
                >
                  <CustomizedGrid item width="100%" height="auto" style={{ marginBottom: '60px' }}>
                    {formStep === Steps.CAMPAIGN_STEP && <CampaignForm />}
                    {formStep === Steps.COLLECTION_STEP && (
                      <CollectionForm
                        setStep={setFormStep}
                        goToStep={goToStep}
                        setGoToStep={setGoToStep}
                        abortControllerRef={uploadCollectionAbortControllerRef}
                      />
                    )}
                    {formStep === Steps.MINTING_STEP && <MintingForm />}
                    {formStep === Steps.OVERVIEW_STEP && <ReviewForm setStep={setFormStep} />}
                  </CustomizedGrid>
                  <CustomizedGrid item width="100%" height="auto">
                    <ActionButtons
                      step={formStep}
                      setStep={setFormStep}
                      goToStep={goToStep}
                      setGoToStep={setGoToStep}
                      abortControllerRef={uploadCollectionAbortControllerRef}
                      isDashboard={!!campaign}
                    />
                  </CustomizedGrid>
                </CustomizedGrid>
              </CustomizedGrid>
            </CustomizedGrid>
          </ThemeProvider>
        </CustomizedCard>
      )}
    </CustomizedGrid>
  );
};

export default CampaignFormAggregator;
