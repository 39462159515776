import { Grid } from '@material-ui/core';
import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { config } from './ config';
import ReviewCard from './ReviewCard/ReviewCard';

interface ReviewFormProps {
  setStep: (step: number) => void;
}

const ReviewForm = ({ setStep }: ReviewFormProps): JSX.Element => {
  const { campaignForm } = useCampaignFormContext();
  const { campaignProps, collectionProps, mintingProps } = config(campaignForm);
  return (
    <Grid container direction="row" spacing={3} data-testid="reviewForm">
      <Grid item xs={7}>
        <ReviewCard
          props={campaignProps}
          step={0}
          setStep={setStep}
          cardTitle="Campaign"
          css={{ border: '2px solid #DFDEDE' }}
        />
      </Grid>
      <Grid item xs={5}>
        <Grid container direction="column">
          <ReviewCard
            props={collectionProps}
            step={1}
            setStep={setStep}
            cardTitle="Collection"
            css={{ border: '2px solid #DFDEDE' }}
          />
          <ReviewCard
            props={mintingProps}
            step={2}
            setStep={setStep}
            cardTitle="Minting"
            css={{ marginTop: '35px', border: '2px solid #DFDEDE' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewForm;
