import { useIntl } from 'react-intl';

export const confirmationConfig = () => {
  const { formatMessage } = useIntl();

  return {
    data: {
      description: (
        <span>
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.setupShopify.confirmation.description',
          })}
        </span>
      ),
      primaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.setupShopify.confirmation.primaryButton',
      }),
      secondaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.setupShopify.confirmation.secondaryButton',
      }),
    },
  };
};
