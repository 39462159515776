import { Button } from '@admin-portal-shared-components/button';
import { Dialog } from '@admin-portal-shared-components/dialog';
import { Heading, Paragraph } from '@admin-portal-shared-components/typography';
import { Grid } from '@material-ui/core';
import { MutableRefObject } from 'react';
import { useIntl } from 'react-intl';

type CancelUploadDialogTypes = {
  isOpen: boolean;
  setIsOpen: () => void;
  goToStep?: number;
  setGoToStep: (step: number) => void;
  setStep: (step: number) => void;
  title: string;
  description: string;
  leftButtonText: string;
  rightButtonText: string;
  abortControllerRef?: MutableRefObject<AbortController>;
};

const CancelUploadDialog: React.FC<CancelUploadDialogTypes> = ({
  isOpen,
  setIsOpen,
  goToStep,
  setGoToStep,
  setStep,
  title,
  description,
  leftButtonText,
  rightButtonText,
  abortControllerRef,
}) => {
  const { formatMessage } = useIntl();

  const handleCancel = () => {
    abortControllerRef.current.abort();
    if (goToStep || goToStep === 0) {
      setStep(goToStep);
    }
    setGoToStep(null);
    setIsOpen();
  };

  return (
    <Dialog
      onClose={setIsOpen}
      open={isOpen}
      title={<Heading size="H3">{formatMessage({ id: title })}</Heading>}
    >
      <Grid container direction="column" data-testid="cancelUploadDialog">
        <Grid item style={{ paddingTop: '30px' }}>
          <Paragraph size="small">{formatMessage({ id: description })}</Paragraph>
        </Grid>
        <Grid item style={{ paddingTop: '20px' }}>
          <Grid container justifyContent="flex-end">
            <Grid item style={{ marginRight: '16px' }}>
              <Button
                onClick={handleCancel}
                variant="primary"
                css={{ background: '#C9201D' }}
                data-testid="leftButton"
              >
                {formatMessage({ id: leftButtonText })}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => setIsOpen()} variant="secondary" data-testid="rightButton">
                {formatMessage({ id: rightButtonText })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CancelUploadDialog;
