import styled from 'styled-components';

type CollectionWrapperProps = {
  isVisible: boolean;
};

export const CollectionWrapper = styled.div<CollectionWrapperProps>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
