import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import CampaignDashboardPage from './pages/CampaignDashboardPage/CampaignDashboardPage';
import CampaignContextProvider from './utils/context/campaignContext/campaign.context';
import CampaignFormContextProvider from './utils/context/campaignFormContext/campaignForm.context';

const HomePage = lazy(() => import('./pages/Home/HomePage'));
const NewCampaignPage = lazy(() => import('./pages/NewCampaignPage/NewCampaignPage'));

const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="/nftcore"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignContextProvider>
              <HomePage />
            </CampaignContextProvider>
          </Suspense>
        }
      />
      <Route
        path="/nftcore/campaign"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignFormContextProvider>
              <NewCampaignPage />
            </CampaignFormContextProvider>
          </Suspense>
        }
      />
      <Route
        path="/nftcore/campaign/:id"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignFormContextProvider>
              <NewCampaignPage />
            </CampaignFormContextProvider>
          </Suspense>
        }
      />
      <Route
        path="/nftcore/campaign/:id/dashboard/:campaign"
        element={
          <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
            <CampaignContextProvider>
              <CampaignFormContextProvider>
                <CampaignDashboardPage />
              </CampaignFormContextProvider>
            </CampaignContextProvider>
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/nftcore" replace />} />
    </Routes>
  );
};
export default Router;
