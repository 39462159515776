import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph, TextLink } from '@hexa-ui/components';
import { Image } from '@hexa-ui/icons';
import { OpenInNew } from '@material-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';

import Confirmation from '../Confirmation/Confirmation';
import Loading from '../Loading/Loading';

import { useCampaignId } from '../../../../Home/hooks/useCampaignId/useCampaignId';
import { useUploadToIpfs } from '../../../../Home/hooks/useUploadToIpfs/useUploadToIpfs';
import { checkHasCollection } from '../../../../Home/utils/checkHasCollection';
import { CardContainerStatus } from '../../CardContainer/types';
import { CardWrapper, LinksWrapper, TextLinkWrapper } from '../ContentCard.styles';
import { UploadToIpfsProps } from '../types';
import { confirmationConfig } from './config';

const UploadArtToIpfs = ({ id, status }: UploadToIpfsProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const { data } = confirmationConfig();
  const { formatMessage } = useIntl();
  const uploadToIpfs = useUploadToIpfs(id);
  const toastService = useToast();
  const hasCollection = checkHasCollection(id);
  const campaignData = useCampaignId(id);
  const isDisabled = status === CardContainerStatus.DISABLED;

  const handleClick = async () => {
    setIsLoading(true);
    const errorMessage = await uploadToIpfs();
    errorMessage
      ? toastService.notify({
          type: TypeToast.ERROR,
          message: formatMessage({ id: errorMessage }),
        })
      : toastService.notify({
          type: TypeToast.SUCCESS,
          message: formatMessage({
            id: 'campaignDashboardPage.contentCard.uploadToIpfs.toastMessage',
          }),
        });
    setIsLoading(false);
  };

  return (
    <CardWrapper data-testid="upload-to-ipfs">
      <Card border="medium" elevated="small" className={isDisabled ? 'card disabled' : 'card'}>
        {!isLoading && !confirmationStep && (
          <>
            <Image size="xlarge" className="icon" />
            <Heading size="H3">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.uploadToIpfs.header',
              })}
            </Heading>
          </>
        )}

        {!hasCollection && !confirmationStep && !isLoading && (
          <>
            <Paragraph size="basis">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.uploadToIpfs.firstState.description',
              })}
            </Paragraph>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => setConfirmationStep(true)}
              data-testid="upload-button"
              disabled={isDisabled}
            >
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.uploadToIpfs.firstState.button',
              })}
            </Button>
          </>
        )}

        {confirmationStep && (
          <Confirmation
            props={data}
            setConfirmationStep={setConfirmationStep}
            setIsConfirmed={setIsConfirmed}
            request={handleClick}
          />
        )}

        {isLoading && isConfirmed && (
          <Loading
            message={formatMessage({
              id: 'campaignDashboardPage.contentCard.uploadToIpfs.loadingMessage',
            })}
          />
        )}

        {hasCollection && (
          <>
            <Paragraph size="basis">
              {formatMessage({
                id: 'campaignDashboardPage.contentCard.uploadToIpfs.secondState.description',
              })}
            </Paragraph>
            <LinksWrapper data-testid="ipfs-links-wrapper">
              <TextLinkWrapper>
                <OpenInNew />
                <TextLink href={campaignData.ipfs.baseUri} target="_blank" size="basis">
                  {formatMessage({
                    id: 'campaignDashboardPage.contentCard.uploadToIpfs.secondState.link',
                  })}
                </TextLink>
              </TextLinkWrapper>
            </LinksWrapper>
          </>
        )}
      </Card>
    </CardWrapper>
  );
};

export default UploadArtToIpfs;
