import { useCallback } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { getSasToken } from '../../services/getSasToken/getSasToken';
import { GetSasTokenResponse, StorageOperations } from './useGetSasToken.types';

const useGetSasToken = (): Function => {
  const { BEARERTOKEN } = useEnvContext();
  return useCallback(
    async (type: StorageOperations, abortControllerSignal?: AbortSignal): Promise<string> => {
      try {
        const response: GetSasTokenResponse = await getSasToken(
          getApiHost(),
          BEARERTOKEN,
          type,
          abortControllerSignal
        );
        return response.data;
      } catch (error) {
        return error;
      }
    },
    []
  );
};

export { useGetSasToken };
