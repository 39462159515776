import { ICampaignAction } from './campaign.actions';
import { ICampaignContext, ICampaignReducerAction } from './campaign.types';

export const campaignReducer = (
  state: ICampaignContext,
  action: ICampaignReducerAction
): ICampaignContext => {
  switch (action.type) {
    case ICampaignAction.CAMPAIGN_REQUEST: {
      return {
        ...state,
        isLoadingCampaign: true,
        campaigns: [],
        campaignError: null,
      };
    }
    case ICampaignAction.CAMPAIGN_SUCCESS: {
      return {
        ...state,
        isLoadingCampaign: false,
        campaigns: action.payload,
        campaignError: null,
      };
    }
    case ICampaignAction.CAMPAIGN_ERROR: {
      return {
        ...state,
        isLoadingCampaign: false,
        campaigns: [],
        campaignError: action.payload,
      };
    }
    default:
      return state;
  }
};
