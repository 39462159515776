import { useIntl } from 'react-intl';

import { Button, Card, Heading, Paragraph } from '@hexa-ui/components';
import { Wallet } from '@hexa-ui/icons';

import { CardContainerStatus } from '../../CardContainer/types';
import { CardWrapper } from '../ContentCard.styles';
import { ClaimingProps } from '../types';

const Claiming = ({ status }: ClaimingProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const isDisabled = status === CardContainerStatus.DISABLED;

  return (
    <CardWrapper>
      <Card
        border="medium"
        elevated="small"
        data-testid="claiming-card"
        className={isDisabled ? 'card disabled' : 'card'}
      >
        <Wallet size="xlarge" className="icon" />
        <Heading size="H3">
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.claiming.header',
          })}
        </Heading>
        <Paragraph size="basis">
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.claiming.description',
          })}
        </Paragraph>
        <Button variant="secondary" size="medium" data-testid="show-button" disabled={isDisabled}>
          {formatMessage({
            id: 'campaignDashboardPage.contentCard.claiming.button',
          })}
        </Button>
      </Card>
    </CardWrapper>
  );
};

export default Claiming;
