import { Button } from '@admin-portal-shared-components/button';
import { DocumentSigned } from '@admin-portal-shared-components/icons';
import { Input } from '@admin-portal-shared-components/input';
import { RadioItem } from '@admin-portal-shared-components/radio';
import { Select, SelectOption } from '@admin-portal-shared-components/select';
import { Grid, InputLabel } from '@material-ui/core';
import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OPTIMIZELY_KEYS, OPTIMIZELY_PROJECT } from '../../../../optimizely/config';
import { useCampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useDownloadAllowlist } from '../../hooks/useDownloadAllowlist/useDownloadAllowlist';
import Allowlist from '../Allowlist/Allowlist';
import { RadioGroupWrapper, RadioItemDescription, RadioWrapper } from './MintingForm.styles';

const MintingForm = (): JSX.Element => {
  const { campaignForm, setCampaignFormSuccess } = useCampaignFormContext();
  const { formatMessage } = useIntl();
  const [isAllowListOpen, setIsAllowListOpen] = useState(false);
  const downloadAllowlist = useDownloadAllowlist();
  const [featureToggleStatus, isLoading] = useFeatureToggleV2(
    OPTIMIZELY_KEYS.GAS_CAP_VALIDATOR,
    OPTIMIZELY_PROJECT
  );

  const handleAllowList = () => {
    setIsAllowListOpen(!isAllowListOpen);
  };

  /* istanbul ignore next */
  const selectHandler = (value: string) => {
    setCampaignFormSuccess({
      ...campaignForm,
      minting: {
        ...campaignForm.minting,
        blockChain: value,
      },
    });
  };

  const inputHandler = (e: React.ChangeEvent<any>) => {
    const gasFeeCap = Number(e.target.value);

    setCampaignFormSuccess({
      ...campaignForm,
      minting: {
        ...campaignForm.minting,
        gasFeeCap: gasFeeCap,
      },
    });
  };

  const handleDownloadCsv = async () => {
    const csvFile = await downloadAllowlist(true);

    const csvFileUrl = window.URL.createObjectURL(csvFile);

    window.open(csvFileUrl, 'download');
  };

  return (
    <Grid container direction="column" spacing={3} data-testid="mintingForm">
      <Grid item style={{ marginBottom: '-18px' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <InputLabel>
              {formatMessage({ id: 'campaignFormAggregator.mintingForm.timing.title' })}
            </InputLabel>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={3} alignItems="flex-end">
          <RadioGroupWrapper data-testid="radioGroupWrapper">
            <Grid item xs={6} style={{ flexBasis: '48%' }}>
              <RadioWrapper isDisabled={campaignForm.minting.postMinting}>
                <RadioItem
                  checked={!campaignForm.minting.postMinting}
                  id="premint"
                  label={formatMessage({ id: 'campaignFormAggregator.mintingForm.timing.preMint' })}
                  value="Pre mint"
                  data-testid="radioPreMint"
                  disabled
                />

                <RadioItemDescription isDisabled={campaignForm.minting.postMinting}>
                  {formatMessage({
                    id: 'campaignFormAggregator.mintingForm.timing.preMintDescription',
                  })}
                </RadioItemDescription>
              </RadioWrapper>
            </Grid>
            <Grid item xs={6} style={{ flexBasis: '48%' }}>
              <RadioWrapper isDisabled={!campaignForm.minting.postMinting}>
                <RadioItem
                  checked={campaignForm.minting.postMinting}
                  id="postmint"
                  label={formatMessage({
                    id: 'campaignFormAggregator.mintingForm.timing.postMint',
                  })}
                  value="Post mint"
                  data-testid="radioPostMint"
                />

                <RadioItemDescription isDisabled={!campaignForm.minting.postMinting}>
                  {formatMessage({
                    id: 'campaignFormAggregator.mintingForm.timing.postMintDescription',
                  })}
                </RadioItemDescription>
              </RadioWrapper>
            </Grid>
          </RadioGroupWrapper>
        </Grid>
      </Grid>

      <Grid item>
        <Select
          width="100%"
          name="blockchain"
          label={formatMessage({ id: 'campaignFormAggregator.mintingForm.blockchain.title' })}
          placeholder="Ethereum"
          shape="square"
          data-testid="blockchainSelect"
          onChange={selectHandler}
          disabled
        >
          <SelectOption key="1" value="Ethereum">
            {formatMessage({ id: 'campaignFormAggregator.mintingForm.blockchain.ethereum' })}
          </SelectOption>
        </Select>
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Input
              min={1}
              name="gasFeeCap"
              width="100%"
              label={formatMessage({ id: 'campaignFormAggregator.mintingForm.gasFeeCap.title' })}
              placeholder="$ 75"
              type="number"
              data-testid="gasFeeCapInput"
              onChange={(e) => inputHandler(e)}
              disabled={!!!featureToggleStatus}
            />
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', marginBottom: '4px' }}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <Button
                  onClick={handleAllowList}
                  variant="secondary"
                  data-testid="mintingAllowListButton"
                >
                  {campaignForm.minting.allowList.length
                    ? formatMessage({
                        id: 'campaignFormAggregator.mintingForm.buttons.editMinters',
                      })
                    : formatMessage({
                        id: 'campaignFormAggregator.mintingForm.buttons.addMinters',
                      })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="secondary"
                  leading
                  onClick={handleDownloadCsv}
                  icon={DocumentSigned}
                  style={{
                    display: campaignForm.minting.allowList.length ? 'flex' : 'none',
                  }}
                  data-testid="mintingDownloadAllowListButton"
                >
                  {formatMessage({ id: 'campaignFormAggregator.mintingForm.buttons.download' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Allowlist
        isOpen={isAllowListOpen}
        setIsOpen={handleAllowList}
        isMinters={true}
        title="campaignFormAggregator.mintingForm.allowlist.title"
      />
    </Grid>
  );
};

export default MintingForm;
