import axios from 'axios';

const getSasToken = async (
  baseUrl: string,
  bearerToken: string,
  type: string,
  abortControllerSignal: AbortSignal
): Promise<any> => {
  return axios.post(
    `${baseUrl}azure/storage/sastoken`,
    { type: type },
    { signal: abortControllerSignal, headers: { authorization: bearerToken } }
  );
};

export { getSasToken };
