import { Grid } from '@hexa-ui/components';
import { useCampaignId } from '../../../Home/hooks/useCampaignId/useCampaignId';
import CardContainer from '../CardContainer/CardContainer';
import Claiming from '../ContentCard/Claiming/Claiming';
import OpenSeaCollection from '../ContentCard/OpenSeaCollection/OpenSeaCollection';
import PublishContract from '../ContentCard/PublishContract/PublishContract';
import SetupShopify from '../ContentCard/SetupShopify/SetupShopify';
import TestContract from '../ContentCard/TestContract/TestContract';
import UploadArtToIpfs from '../ContentCard/UploadArtToIpfs/UploadArtToIpfs';
import DividerComponent from '../Divider/DividerComponent';
import { getCardProps } from './getCardProps';
import { BasicSetupWrapperProps } from './types';

const { Container, Item } = Grid;

const BasicSetupWrapper: React.FC<BasicSetupWrapperProps> = ({ id }: BasicSetupWrapperProps) => {
  const campaignData = useCampaignId(id);
  const { contractProps, marketplaceProps, collectionProps } = getCardProps(campaignData);

  return (
    <Container type="fluid" data-testid="basic-setup-wrapper">
      <Item lg={12} style={{ justifyContent: 'space-between' }}>
        <CardContainer
          title="Contract"
          description="Set up the campaign smart contract"
          position={1}
          status={contractProps.status}
        >
          <TestContract {...contractProps} />
          <PublishContract {...contractProps} />
        </CardContainer>
        <DividerComponent orientation="vertical" />
        <CardContainer
          title="Marketplace"
          description="Details on the campaign store"
          position={2}
          status={marketplaceProps.status}
        >
          <SetupShopify {...marketplaceProps} />
          <Claiming {...marketplaceProps} />
        </CardContainer>
        <DividerComponent orientation="vertical" />
        <CardContainer
          title="Collection"
          description="View campaign assets details"
          position={3}
          status={collectionProps.status}
        >
          <UploadArtToIpfs {...collectionProps} />
          <OpenSeaCollection {...collectionProps} />
        </CardContainer>
      </Item>
    </Container>
  );
};

export default BasicSetupWrapper;
