import { Paragraph } from '@admin-portal-shared-components/typography';
import { makeStyles, StepIconProps } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#000',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#000',
    color: '#fff',
    width: 35,
    height: 35,
  },
  completed: {
    backgroundColor: '#000',
    color: '#fff',
    width: 35,
    height: 35,
  },
});

interface SetupStepIconProps extends StepIconProps {
  step: number;
}

export function SetupStepIcon(props: SetupStepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, step } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {active && <Paragraph style={{ color: '#fff' }}>{step}</Paragraph>}
      {completed && <Check />}
      {!active && !completed && <Paragraph color="#000">{step}</Paragraph>}
    </div>
  );
}
