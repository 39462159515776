import { BlobServiceClient } from '@azure/storage-blob';
import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { validateCollectionJson } from '../../../../utils/validators/collectionJsonValidator/collectionJsonValidator';

import { uploadFile } from '../../services/uploadFile/uploadFile';

const useUploadCollection = (): Function => {
  const { campaignForm, setIsUploading, setCampaignFormSuccess } = useContext(CampaignFormContext);
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();

  return useCallback(
    async (
      files: File[],
      sasToken: string,
      setUploadProgress: Dispatch<SetStateAction<number>>,
      abortControllerSignal: AbortSignal
    ) => {
      try {
        let nftQuantity = 0;
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        for (const file of files) {
          if (file.type === 'application/json') {
            validateCollectionJson(file);
            nftQuantity++;
          }
          const blockBlobClient = containerClient.getBlockBlobClient(
            campaignForm.id + '/collection/' + file.name
          );
          await uploadFile(blockBlobClient, file, abortControllerSignal);
          setUploadProgress(((files.indexOf(file) + 1) / files.length) * 100);
        }

        setCampaignFormSuccess({
          ...campaignForm,
          nftStorage: {
            uploaded: true,
            baseUri: '',
            filesCount: files.length,
            nftQuantity: nftQuantity,
            uploadDate: new Date().toISOString(),
          },
        });
      } catch (error) {
        setIsUploading(false);
        setCampaignFormSuccess({
          ...campaignForm,
          nftStorage: {
            uploaded: false,
            baseUri: '',
            filesCount: 0,
            nftQuantity: 0,
            uploadDate: null,
          },
        });

        throw new Error(error.message);
      }
    },
    [campaignForm, setIsUploading, setCampaignFormSuccess]
  );
};

export { useUploadCollection };
