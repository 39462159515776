import { BlobItem, BlobServiceClient } from '@azure/storage-blob';
import { useCallback, useContext } from 'react';
import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { listCollection } from '../../services/listCollection/listCollection';

const useListCollection = (): Function => {
  const { campaignForm } = useContext(CampaignFormContext);
  const { STORAGE_ACCOUNT_NAME } = useEnvContext();
  return useCallback(
    async (
      sasToken: string,
      abortControllerSignal: AbortSignal
    ): Promise<AsyncIterableIterator<BlobItem>> => {
      try {
        const accountName = STORAGE_ACCOUNT_NAME;
        const containerName = 'beesnftstorage';

        const blobService = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        return listCollection(containerClient, campaignForm.id, abortControllerSignal);
      } catch (error) {
        console.info(error);
      }
    },
    [campaignForm]
  );
};

export { useListCollection };
