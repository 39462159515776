import { useCallback, useContext } from 'react';

import { CampaignFormContext } from '../../../../utils/context/campaignFormContext/campaignForm.context';

const useDownloadAllowlist = (): Function => {
  const { campaignForm } = useContext(CampaignFormContext);

  return useCallback(
    async (isMinters: boolean): Promise<File> => {
      try {
        if (isMinters && !campaignForm.minting.allowList.length) {
          throw new Error('Minters allowlist does not exist!');
        }

        if (!isMinters && !campaignForm.preSale.allowList.length) {
          throw new Error('Presale allowlist does not exist!');
        }

        const allowList = isMinters
          ? campaignForm.minting.allowList
          : campaignForm.preSale.allowList;

        const walletAddresses = allowList.map((item) => {
          return item.walletAddress;
        });

        const csvContent = `Wallet\n${walletAddresses.join('\n')}`;

        const csvName = `${campaignForm.id}-${isMinters ? 'minters' : 'preSale'}-allowlist`;

        return new File([csvContent], csvName, { type: 'text/csv' });
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [campaignForm]
  );
};

export { useDownloadAllowlist };
