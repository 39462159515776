import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { CampaignContext } from '../../../../utils/context/campaignContext/campaign.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { generateContractErrorMapper } from '../../mappers/generateContractErrorMapper/generateContractErrorMapper';
import { generateContractService } from '../../services/generateContract/generateContract';
import { Network } from './types';

const useGenerateContract = (
  id: string,
  isLiveContract: boolean,
  network?: Network
): (() => Promise<string>) => {
  const { BEARERTOKEN } = useEnvContext();
  const { setCampaignRequest, setCampaignSuccess, campaigns } = useContext(CampaignContext);

  return useCallback(async () => {
    try {
      const response = await generateContractService(
        getApiHost(),
        BEARERTOKEN,
        id,
        isLiveContract,
        network
      );

      const campaignIndex = campaigns.findIndex((campaign) => campaign.id === id);
      const updatedCampaigns = campaigns;

      if (isLiveContract) {
        updatedCampaigns[campaignIndex].liveContract = {
          blockChain: 'goerli',
          hash: response?.data.hash,
          publishedDate: response?.data.publishedDate,
          isPublishing: false,
        };

        if (!campaigns[campaignIndex].liveContract?.hash) {
          throw new Error('Request failed. No hash returned!');
        }

        setCampaignSuccess(updatedCampaigns);
      } else {
        updatedCampaigns[campaignIndex].testNetContract = {
          blockChain: 'goerli',
          hash: response?.data.hash,
          publishedDate: response?.data.publishedDate,
          isPublishing: false,
        };

        if (!campaigns[campaignIndex].testNetContract?.hash) {
          throw new Error('Request failed. No hash returned!');
        }

        setCampaignSuccess(updatedCampaigns);
      }

      return '';
    } catch (error) {
      setCampaignSuccess(campaigns);
      if (error?.response?.data?.name) {
        return generateContractErrorMapper(error?.response?.data?.name);
      } else {
        return error.message;
      }
    }
  }, [setCampaignSuccess, setCampaignRequest, getApiHost(), BEARERTOKEN, campaigns]);
};

export { useGenerateContract };
