import { LoadingDots, Paragraph } from '@hexa-ui/components';
import { LoadingWrapper } from '../ContentCard.styles';

interface LoadingProps {
  message: string;
}

const Loading = ({ message }: LoadingProps): JSX.Element => {
  return (
    <LoadingWrapper data-testid="loading">
      <Paragraph type="basis">{message}</Paragraph>
      <LoadingDots size="large" />
    </LoadingWrapper>
  );
};

export default Loading;
