import { Input } from '@hexa-ui/components';
import { useIntl } from 'react-intl';

export const confirmationConfig = () => {
  const { formatMessage } = useIntl();

  return {
    data: {
      element: (
        <Input
          placeholder={formatMessage({
            id: 'campaignDashboardPage.contentCard.openSeaCollection.confirmation.inputPlaceholder',
          })}
        />
      ),
      primaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.openSeaCollection.confirmation.primaryButton',
      }),
      secondaryButton: formatMessage({
        id: 'campaignDashboardPage.contentCard.openSeaCollection.confirmation.secondaryButton',
      }),
    },
  };
};
