import { Heading, Paragraph } from '@admin-portal-shared-components/typography';

interface CustomHeadingProps {
  children: Array<React.ReactChild> | React.ReactChild;
  type: 'Hero' | 'H1' | 'H2' | 'H3' | 'H4';
  css?: any;
}

export const CustomHeading = ({ children, type, css }: CustomHeadingProps): JSX.Element => {
  return (
    <Heading size={type} data-testid="Heading" css={css}>
      {children}
    </Heading>
  );
};

interface CustomParagraphProps {
  children: Array<React.ReactChild> | React.ReactChild;
  type: 'basis' | 'small' | 'xsmall';
  weight?: 'normal' | 'medium' | 'semibold';
  css?: any;
}

export const CustomParagraph = ({
  children,
  type,
  weight,
  css,
}: CustomParagraphProps): JSX.Element => {
  return (
    <Paragraph size={type} weight={weight} css={css} data-testid="Paragraph">
      {children}
    </Paragraph>
  );
};
