export const FileUploadIcon = () => {
  return (
    <svg
      width="115"
      height="101"
      viewBox="0 0 115 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1419_22590)">
        <rect x="0.58252" width="94" height="94" rx="16" fill="#D1F7F6" />
        <mask id="mask0_1419_22590" maskUnits="userSpaceOnUse" x="0" y="0" width="95" height="94">
          <rect x="0.58252" width="94" height="94" rx="16" fill="white" />
        </mask>
        <g mask="url(#mask0_1419_22590)">
          <path
            d="M68.4628 78.3123C68.4628 78.8646 68.015 79.3123 67.4628 79.3123H22.145C21.5927 79.3123 21.145 78.8646 21.145 78.3123V20.1104C21.145 19.5581 21.5927 19.1104 22.145 19.1104H67.4628C68.015 19.1104 68.4628 19.5581 68.4628 20.1104V78.3123Z"
            fill="black"
            fillOpacity="0.15"
          />
          <path
            d="M74.0199 73.5378C74.0199 74.2099 73.3701 74.6907 72.7273 74.494L58.3913 70.1081C58.3242 70.0875 58.2551 70.0741 58.1852 70.0681L35.097 68.0642C30.2425 67.213 26.7021 63.0063 26.7021 58.0892V15.6875C26.7021 15.1352 27.1499 14.6875 27.7021 14.6875H73.0199C73.5722 14.6875 74.0199 15.1352 74.0199 15.6875V73.5378Z"
            fill="#FAFAFA"
          />
          <path
            d="M67.4858 14.6875V73.7436L74.02 74.8895V15.6875C74.02 15.1352 73.5723 14.6875 73.02 14.6875L67.4858 14.6875Z"
            fill="#F2F2F2"
          />
          <path
            d="M74.0195 74.8894L57.7175 65.0055C51.804 61.4201 42.2613 68.0733 35.3457 68.0733L74.0195 74.8894Z"
            fill="#DFDEDE"
          />
          <path
            d="M35.402 25.5412H65.3201C65.8614 25.5412 66.3002 25.1046 66.3002 24.566C66.3002 24.0274 65.8614 23.5908 65.3201 23.5908H35.402C34.8607 23.5908 34.4219 24.0274 34.4219 24.566C34.4219 25.1046 34.8607 25.5412 35.402 25.5412Z"
            fill="#A7A7A7"
          />
          <path
            d="M65.3201 29.6035H35.402C34.8607 29.6035 34.4219 30.0401 34.4219 30.5787C34.4219 31.1173 34.8607 31.5539 35.402 31.5539H65.3201C65.8614 31.5539 66.3002 31.1173 66.3002 30.5787C66.3002 30.0401 65.8615 29.6035 65.3201 29.6035V29.6035Z"
            fill="#A7A7A7"
          />
          <path
            d="M65.3201 35.6172H35.402C34.8607 35.6172 34.4219 36.0538 34.4219 36.5924C34.4219 37.131 34.8607 37.5676 35.402 37.5676H65.3201C65.8614 37.5676 66.3002 37.131 66.3002 36.5924C66.3002 36.0538 65.8615 35.6172 65.3201 35.6172Z"
            fill="#A7A7A7"
          />
          <path
            d="M65.3201 41.6299H35.402C34.8607 41.6299 34.4219 42.0665 34.4219 42.6051C34.4219 43.1436 34.8607 43.5802 35.402 43.5802H65.3201C65.8614 43.5802 66.3002 43.1436 66.3002 42.6051C66.3002 42.0665 65.8615 41.6299 65.3201 41.6299Z"
            fill="#A7A7A7"
          />
        </g>
        <ellipse cx="93.0712" cy="79.6119" rx="21.4369" ry="21.3882" fill="white" />
        <mask id="mask1_1419_22590" maskUnits="userSpaceOnUse" x="81" y="67" width="25" height="24">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.157 67.1139C93.98 67.0405 93.786 67 93.5825 67C93.3796 67 93.1861 67.0403 93.0096 67.1133C92.8322 67.1865 92.666 67.2952 92.5219 67.4393L82.0219 77.9393L81.8971 78.0807C81.4395 78.6691 81.4811 79.5199 82.0219 80.0607L82.1632 80.1854C82.7516 80.643 83.6025 80.6014 84.1432 80.0607L92.0825 72.1223V89.5L92.0926 89.6749C92.1793 90.4209 92.8133 91 93.5825 91C94.4109 91 95.0825 90.3284 95.0825 89.5V72.1223L103.022 80.0607L103.163 80.1854C103.752 80.643 104.602 80.6014 105.143 80.0607C105.729 79.4749 105.729 78.5251 105.143 77.9393L94.6432 67.4393C94.5991 67.3952 94.5522 67.3539 94.5029 67.3155L94.5019 67.3146C94.3945 67.2311 94.2784 67.1642 94.157 67.1139Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1419_22590)">
          <rect x="77.5825" y="63" width="32" height="32" fill="#4ABEFF" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1419_22590">
          <rect width="113.926" height="101" fill="white" transform="translate(0.58252)" />
        </clipPath>
      </defs>
    </svg>
  );
};
