import { useCallback, useContext } from 'react';
import getApiHost from '../../../../services/host/HostService';
import { CampaignContext } from '../../../../utils/context/campaignContext/campaign.context';
import { useEnvContext } from '../../../../utils/hooks/EnvProvider/EnvProvider';
import { uploadToIpfsErrorMapper } from '../../../Home/mappers/uploadToIpfsErrorMapper/uploadToIpfsErrorMapper';
import { uploadToIpfsService } from '../../services/uploadToIpfs/uploadToIpfs';

const useUploadToIpfs = (id: string): (() => Promise<string>) => {
  const { setCampaignError, setCampaignSuccess, campaigns } = useContext(CampaignContext);
  const { BEARERTOKEN } = useEnvContext();

  return useCallback(async () => {
    try {
      const updatedCampaigns = campaigns;
      const campaignIndex = campaigns.findIndex((campaign) => campaign.id === id);
      const response = await uploadToIpfsService(getApiHost(), BEARERTOKEN, id);
      updatedCampaigns[campaignIndex].ipfs = response?.data;
      setCampaignSuccess(updatedCampaigns);
      return '';
    } catch (error) {
      if (error?.response?.data?.name) {
        return uploadToIpfsErrorMapper(error?.response?.data?.name);
      } else {
        return error.message;
      }
    }
  }, [setCampaignSuccess, setCampaignError, getApiHost(), BEARERTOKEN]);
};

export { useUploadToIpfs };
