import { Card } from '@admin-portal-shared-components/card';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

type CustomizedCardProps = {
  marginleft?: string;
  marginright?: string;
  margintop?: string;
  borderradius?: string;
  height?: string;
  width?: string;
  minheight?: string;
  minwidth?: string;
  padding?: string;
  gap?: string;
  position?: string;
};

export const CustomizedCard = styled(Card)<CustomizedCardProps>`
  margin-left: ${(props) => props.marginleft};
  margin-right: ${(props) => props.marginright};
  margin-top: ${(props) => props.margintop};
  border-radius: ${(props) => props.borderradius};
  min-height: ${(props) => props.minheight};
  min-width: ${(props) => props.minwidth};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};
  position: relative;
  overflow: hidden;
`;

type GridProps = {
  width: string;
  height: string;
  marginbottom?: string;
  background?: string;
};

export const CustomizedGrid = styled(Grid)<GridProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  margin-bottom: ${(props) => props.marginbottom};

  background: ${(props) => props.background || 'none'};
`;
