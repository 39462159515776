import { Grid } from '@material-ui/core';
import { CustomParagraph } from '../../../../../shared-components/Typography/Typography';

interface CardFieldProps {
  title: string;
  description: string;
}

const CardField = ({ title, description }: CardFieldProps): JSX.Element => {
  return (
    <Grid container direction="column" data-testid="cardField">
      <CustomParagraph type="small" css={{ margin: '0' }}>
        {title}
      </CustomParagraph>
      <CustomParagraph type="basis" weight="semibold" css={{ margin: '0' }}>
        {description}
      </CustomParagraph>
    </Grid>
  );
};

export default CardField;
